/**************************************************
 * Nombre:       Titulo_Texto
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     1 Hr
 **************************************************/
import {Grid, Typography, useMediaQuery} from "@mui/material";
import {theme} from "../../../Tema";

const Titulo_Texto = ({titulo, descripcion}) => {
    const masSM = useMediaQuery(theme.breakpoints.up('xs'))
    const sCell = useMediaQuery(theme.breakpoints.only('xs'))

    return (
        <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="flex-start"

        >
            <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="flex-start"
                sx={{maxWidth: '1400px', px: 3}}
            >

                <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'center'}}>
                    <Typography
                        sx={{fontWeight: 700, fontSize: 32, textAlign: sCell ? 'center': 'left', lineHeight: 1.2}}
                    >{titulo}</Typography>
                </Grid>

                <Grid item container lg={7} sm={10} xs={12} sx={{justifyContent: 'center', marginTop: sCell ? 2 : 0}}>
                    <Typography
                        sx={{fontWeight: 400, fontSize: 18, textAlign: 'center'}}
                    >{descripcion}</Typography>
                </Grid>


            </Grid>


        </Grid>
    )

}
export default Titulo_Texto