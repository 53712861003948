/**************************************************
 * Nombre:       Formulario_socio
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     1 Hr
 **************************************************/
import {Grid} from "@mui/material";
import IngresoTexto from "../../Modulo_formularios/Componentes/IngresoTexto";
import {AccountCircle, Apartment, Email, PhoneAndroid} from "@mui/icons-material";

const Formulario_socio = ({...props}) => {

    return (
        <form noValidate style={{width: '100%'}}>
            <Grid
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="flex-start"
                spacing={2}
            >

                <Grid item container lg={6} sm={6} xs={12} sx={{justifyContent: 'center'}}>
                    <IngresoTexto Icono={AccountCircle} nombre={'Nombre'} dato={"nombre"} {...props}
                                  requerido={'El nombre es requerido*'}/>
                </Grid>

                <Grid item container lg={6} sm={6} xs={12} sx={{justifyContent: 'center'}}>
                    <IngresoTexto Icono={PhoneAndroid} nombre={'Celular'} dato={"celular"} {...props}
                                  requerido={'El celuar es requerido*'}/>
                </Grid>

                <Grid item container lg={6} sm={6} xs={12} sx={{justifyContent: 'center'}}>
                    <IngresoTexto Icono={Email} nombre={'Correo'} dato={"correo"} {...props}
                                  requerido={'El correo es requerido*'}/>
                </Grid>

                <Grid item container lg={6} sm={6} xs={12} sx={{justifyContent: 'center'}}>
                    <IngresoTexto Icono={Apartment} nombre={'Ciudad'} dato={"ciudad"} {...props}
                    />
                </Grid>


                <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'center'}}>
                    <IngresoTexto lineas={4} nombre={'Mensaje'} dato={"mensaje"} {...props}
                    />
                </Grid>

            </Grid>
        </form>

    )

}
export default Formulario_socio