import React, {createContext, useEffect, useState} from 'react';
import logo from './logo.svg';
import './App.css';
import {Grid} from "@mui/material";
import Pagina from "./Pagina/Pagina";
import Dashboard from "./Dashboard/Dashboard";
import {iniciarAnaliticas} from "./Servicios/Analiticas/iniciarAnaliticas";
import {useEscucharAuth} from "./Servicios/Auth/useEscucharAuth";
import {useEscucharUsuarioToCorreo} from "./Servicios/BD/useEscucharUsuarioToCorreo";

export const usuarioContext = createContext();

function App() {
    const {email} = useEscucharAuth()
    const {usuario} = useEscucharUsuarioToCorreo({correo: email})
    const [mesa, setMesa] = useState(0)
    const [puesto, setPuesto] = useState('none')

    const valoresProvider = {
        usuario,
        mesa,
        setMesa,
        puesto,
        setPuesto
    }

    const {Provider} = usuarioContext;


    useEffect(() => {

        iniciarAnaliticas()

        if (window.swUpdateReady) {
            window.swUpdateReady = false;
            window.stop();
            window.location.reload();
        }


    }, [])
    return (
        <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="flex-start"
            sx={{maxWidth: '100%'}}
        >

            <h1 style={{display: 'none'}}> Distribuidora de productos naturales </h1>
            <h2 style={{display: 'none'}}> Productos Naturales </h2>
            <h2 style={{display: 'none'}}> Suplementos Naturales </h2>
            <h2 style={{display: 'none'}}> Proteina Natural </h2>


            <Provider value={valoresProvider}>
                {usuario ?
                    <Dashboard/>
                    :
                    <Pagina/>
                }

            </Provider>

        </Grid>
    );
}

export default App;
