/**************************************************
 * Nombre:       Seccion_Contactos
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     1 Hr
 **************************************************/
import {ButtonBase, Grid, Typography, useMediaQuery} from "@mui/material";
import {CONTACTOS, REDES} from "../../Constantes";
import {theme} from "../../Tema";
import Mapa_Contactos from "./ComponentesSecciones/Mapa_Contactos";
import LogosFraces from "./ComponentesSecciones/LogosFraces";
import Pqrs_Formulario from "./ComponentesSecciones/Pqrs_Formulario";
import {useEffect} from "react";
import {irArriba} from "../../Utilidades/irArriba";
import {useLoaders} from "../../Modulo_Loaders/Hooks/useLoaders";
import logo from "../../Recursos/logo_redondo.svg";
import {guardarDoc} from "../../Servicios/BD/guardarDoc";

const Seccion_Contactos = () => {
    const masSM = useMediaQuery(theme.breakpoints.up('xs'))
    const sCell = useMediaQuery(theme.breakpoints.only('xs'))


    useEffect(() => {
        irArriba()
    }, [])
    return (
        <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="flex-start"
        >
            <Grid item container lg={11} sm={12} xs={12} sx={{justifyContent: 'center'}}>
                <Mapa_Contactos/>
            </Grid>

            <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'flex-start', marginTop: 12}}>
                <LogosFraces/>
            </Grid>

            <Grid item container lg={12} sm={10} xs={11}
                  sx={{justifyContent: 'center', marginTop: 12, marginBottom: 12}}>
                <Pqrs_Formulario/>
            </Grid>


        </Grid>
    )

}
export default Seccion_Contactos