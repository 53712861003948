import {Badge, Button, Dialog, IconButton, useMediaQuery} from "@mui/material";
import {ShoppingCart} from "@mui/icons-material";
import {useState} from "react";
import {theme} from "../../Tema";
import {useFuncionesCarrito} from "./useFuncionesCarrito";
import Dialogo_Carrito from "../Dialogos/Dialogo_Carrito";


export const useCarritoCompras = () => {
    const masSM = useMediaQuery(theme.breakpoints.up('md'))
    const sCell = useMediaQuery(theme.breakpoints.only('xs'))
    const {productos} = useFuncionesCarrito()
    const [open, setOpen] = useState(false)


    const abrir = () => {
        setOpen(true)
    }

    const cerrar = () => {
        setOpen(false)
    }


    const DialogoCarrito = () => {
        return (
            <Dialog open={open} fullWidth maxWidth={'xs'}  sx={{m: sCell ? -2 : 0}}>
                <Dialogo_Carrito cerrar={cerrar}/>
            </Dialog>
        )
    }

    const BotonCarrito = () => {
        return (
            <>
                <DialogoCarrito/>

                {masSM ?
                    <Button
                        onClick={() => abrir()}
                        startIcon={
                            <Badge badgeContent={productos.length} color={'primary'} sx={{marginTop: 0.4}}>
                                <ShoppingCart/>
                            </Badge>

                        }
                    >
                        Carrito
                    </Button> :

                    <IconButton
                        onClick={() => abrir()}
                        sx={{marginTop: -1}}>
                        <Badge badgeContent={productos.length} color={'primary'} sx={{marginTop: 0.4}}>
                            <ShoppingCart/>
                        </Badge>
                    </IconButton>
                }

            </>
        )
    }

    return {
        BotonCarrito,
        abrir,
        DialogoCarrito
    }
}