/**************************************************
 * Nombre:       Barra
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     1 Hr
 **************************************************/
import {Grid, useMediaQuery} from "@mui/material";
import {theme} from "../../Tema";
import BarraPc from "./BarraPc";
import BarraCell from "./BarraCell";

const Barra = () => {
    const masSM = useMediaQuery(theme.breakpoints.up('md'))

    return (
        <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="flex-start"
        >


            {masSM ?
                <BarraPc/>
                :
                <BarraCell/>
            }

        </Grid>
    )

}
export default Barra