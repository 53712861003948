/**************************************************
 * Nombre:       LogosFraces
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     1 Hr
 **************************************************/
import {Grid, Typography, useMediaQuery} from "@mui/material";
import logo from "../../../Recursos/logoenvia.svg";
import logouno from "../../../Recursos/logoservi.svg";
import logodos from "../../../Recursos/logomercado.svg";
import logotres from "../../../Recursos/logovitalim.svg";
import logocuatro from "../../../Recursos/logomacel.svg";
import {theme} from "../../../Tema";

const LogosFraces = () => {
    const masSM = useMediaQuery(theme.breakpoints.up("md"))
    const sCell = useMediaQuery(theme.breakpoints.only("xs"))

    return (
        <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="flex-start"
        >


            <Grid
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="center"
                sx={{maxWidth: "1400px", px: 3}}
            >

                <Grid item lg={7} sm={12} xs={12}>
                    <Grid
                        container
                        direction="row"
                        justifyContent="flex-start"
                        alignItems="center"
                    >

                        <Grid item lg={12} sm={1} xs={1}>

                        </Grid>

                        <Grid item container lg={2} sm={2} xs={2} sx={{justifyContent: "center"}}>
                            <img src={logo} width={"70%"}/>
                        </Grid>

                        <Grid item container lg={2} sm={2} xs={2} sx={{justifyContent: "center"}}>
                            <img src={logouno} width={"60%"}/>
                        </Grid>

                        <Grid item container lg={3} sm={3} xs={3} sx={{justifyContent: "center"}}>
                            <img src={logodos} width={"80%"}/>
                        </Grid>

                        <Grid item container lg={2} sm={2} xs={2} sx={{justifyContent: "center"}}>
                            <img src={logotres} width={"60%"}/>
                        </Grid>

                        <Grid item container lg={1} sm={1} xs={1} sx={{justifyContent: "center"}}>
                            <img src={logocuatro} width={"70%"}/>
                        </Grid>

                        <Grid item lg={12} sm={1} xs={1}>

                        </Grid>

                    </Grid>
                </Grid>

                <Grid container item lg={5} sm={12} xs={12} justifyContent={"center"}>
                    <Typography
                        sx={{fontWeight: 400, fontSize: sCell ? 10 : 16, color: "#00000080", textAlign: "center"}}>Aliados
                        con las mejores marcas
                        para
                        brindarte el mejor servicio en tu compra,
                        entrega y gestión de tus pedidos nacionales</Typography>
                </Grid> </Grid>

        </Grid>
    )

}
export default LogosFraces