// Import the functions you need from the SDKs you need
import {initializeApp} from "firebase/app";
import {getAnalytics} from "firebase/analytics";
import {getAuth} from 'firebase/auth'
import {getFirestore} from 'firebase/firestore'
import {getStorage} from 'firebase/storage'
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
// narinosouvenirs.colombia@gmail.com
const firebaseConfig = {
    apiKey: "AIzaSyCu-73I8fxDbNjnhBIOeHqo5HRDJUk_P6M",
    authDomain: "dispronan-colombia.firebaseapp.com",
    projectId: "dispronan-colombia",
    storageBucket: "dispronan-colombia.appspot.com",
    messagingSenderId: "266009809102",
    appId: "1:266009809102:web:175f79c3e62190627a009f",
    measurementId: "G-0TNWHDFKJM"
};

// Initialize Firebase
export const app = initializeApp(firebaseConfig);
export const analytics = getAnalytics(app);

export const auth = getAuth(app)
export const fire = getFirestore(app)
export const storage = getStorage(app)