export const adaptador_pedido = ({productos, valorEnvio, datos}) => {


    let arr = []

    for (let i = 0; i < productos.length; i++) {
        let producto = productos[i]
        let prod = {
            nombre: producto.nombre,
            categoria: producto.categoria,
            descuento: producto.descuento ? producto.descuento : 0,
            valorFinal: producto.valorFinal,
            img: producto.img,
            cantidad: producto.cantidad
        }

        arr.push(prod)
    }

    let obj = {
        fecha: new Date().getTime(),
        valorEnvio: valorEnvio,
        productos: arr,
        ...datos
    }

    return obj
}