/**************************************************
 * Nombre:       Tarjeta_Producto
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     1 Hr
 **************************************************/
import {ButtonBase, Grid, Typography, useMediaQuery} from "@mui/material";
import {theme} from "../../../Tema";
import {formatoMoneda} from "../../../Utilidades/formatoMoneda";
import {obtenerDescuento} from "../../../Utilidades/obtenerDescuento";
import {useNavigate} from 'react-router-dom'

const Tarjeta_Producto = ({item}) => {
    const masSM = useMediaQuery(theme.breakpoints.up('xs'))
    const sCell = useMediaQuery(theme.breakpoints.only('xs'))
    const navigate = useNavigate()

    return (
        <ButtonBase
            onClick={() => navigate('/Detalle/' + item.id)}
            sx={{textAlign: 'left', width: '100%'}}>
            <Grid
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="flex-start"
                sx={{marginTop: sCell ? 2 : 0}}
                spacing={4}
            >


                <Grid item container lg={5} sm={12} xs={12} sx={{justifyContent: 'flex-start'}}>
                    <Grid
                        container
                        direction="row"
                        justifyContent="center"
                        alignItems="flex-start"

                    >

                        <Grid item container lg={12} sm={12} xs={12}
                              sx={{justifyContent: 'flex-start', marginBottom: -6, zIndex: 2, marginTop: 0.5}}>
                            {item && item.rebajado > 0 &&
                                <Typography
                                    sx={{
                                        fontSize: 16,
                                        fontWeight: 600,
                                        lineHeight: 1.3,
                                        color: "#fff",
                                        backgroundColor: "#C3C700", width: 60, px: 0, py: 0.5,
                                        textAlign: 'center',
                                        borderRadius: 5
                                    }}>{`-${obtenerDescuento({
                                    valorNormal: item.valor,
                                    valorRebajado: item.rebajado
                                })}%`}
                                </Typography>
                            }

                        </Grid>

                        <Grid item container lg={12} sm={12} xs={12}
                              sx={{justifyContent: 'flex-start', borderRadius: 4, border: 8, borderColor: '#C3C700'}}>
                            <img src={item.img} width={'100%'} height={'auto'}
                                 style={{filter: 'drop-shadow(0px 1px 7px #00000090)'}}
                            />
                        </Grid>

                    </Grid>

                </Grid>

                <Grid item container lg={7} sm={12} xs={12} sx={{justifyContent: 'flex-start'}}>
                    <Grid
                        container
                        direction="row"
                        justifyContent="center"
                        alignItems="flex-start"
                    >

                        <Grid item lg={12} sm={12} xs={12}>
                            <Typography
                                sx={{fontSize: sCell ? 18 : 20, fontWeight: 500, lineHeight: 1.3}}>
                                {item.nombre}
                            </Typography>
                        </Grid>


                        <Grid item lg={12} sm={12} xs={12} sx={{marginTop: 1}}>
                            <Typography component={"p"}
                                        sx={{
                                            fontSize: sCell ? 12 : 14,
                                            fontWeight: 400,
                                            textOverflow: "ellipsis",
                                            display: '-webkit-box',
                                            overflow: 'hidden',
                                            WebkitBoxOrient: 'vertical',
                                            WebkitLineClamp: sCell ? 6 : 4,
                                            lineHeight: 1.3
                                        }}>
                                {item.descripcion}
                            </Typography>
                        </Grid>
                        {item.rebajado > 0 &&
                            <Grid item container lg={12} sm={12} xs={12}
                                  sx={{
                                      justifyContent: 'flex-start',
                                      marginTop: sCell ? 1 : 2,
                                      marginBottom: sCell ? -1.5 : -2.5
                                  }}>
                                <Typography
                                    sx={{
                                        color: '#7e7e7e',
                                        fontSize: 12,
                                        fontWeight: sCell ? 400 : 600,
                                        textDecoration: 'line-through'
                                    }}>
                                    {`Antes ${formatoMoneda(item.valor)}`}
                                </Typography>
                            </Grid>
                        }
                        <Grid item container lg={12} sm={12} xs={12}
                              sx={{justifyContent: 'flex-start', marginTop: sCell ? 1 : 2}}>
                            <Typography
                                sx={{color: theme.palette.secondary.main, fontSize: sCell ? 20 : 22, fontWeight: 600}}>
                                {item.rebajado > 0 ? formatoMoneda(item.rebajado) : formatoMoneda(item.valor)}
                            </Typography>
                        </Grid>

                        <Grid item container lg={12} sm={12} xs={12}
                              sx={{justifyContent: 'flex-start', marginTop: sCell ? 0 : 1}}>
                            {/*} <Grid
                                container
                                direction="row"
                                justifyContent="center"
                                alignItems="center"
                            >

                                <Grid item container lg={1} sm={1} xs={2} sx={{justifyContent: 'flex-start'}}>
                                    <AccountCircle color={'primary'} sx={{height: 25, width: 25}}/>
                                </Grid>

                                <Grid item container lg={11} sm={10} xs={10}
                                      sx={{justifyContent: 'flex-start', marginTop: 0.5, pl: 1}}>
                                    <Typography sx={{fontSize: 11, fontWeight: 400}}>
                                        +15 les interesa este producto
                                    </Typography>
                                </Grid>


                            </Grid>*/}
                        </Grid>

                    </Grid>
                </Grid>

            </Grid>
        </ButtonBase>
    )

}
export default Tarjeta_Producto    