/**************************************************
 * Nombre:       Seccion_Nosotros
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     1 Hr
 **************************************************/
import {Grid, useMediaQuery} from "@mui/material";
import Titulo_Texto from "./ComponentesSecciones/Titulo_Texto";
import logo from '../../Recursos/logo_oscuro.svg'
import Titulo_Texto_Grande from "./ComponentesSecciones/Titulo_Texto_Grande";
import Personal from "./ComponentesSecciones/Personal";
import NuestroSocio from "./ComponentesSecciones/NuestroSocio";
import LogosFraces from "./ComponentesSecciones/LogosFraces";
import {theme} from "../../Tema";
import {useEffect} from "react";
import {irArriba} from "../../Utilidades/irArriba";

const Seccion_Nosotros = () => {
    const masSM = useMediaQuery(theme.breakpoints.up('xs'))
    const sCell = useMediaQuery(theme.breakpoints.only('xs'))

    useEffect(() => {
       irArriba()
    },[])
    return (
        <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="flex-start"
        >

            <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="flex-start"
                sx={{maxWidth: '1400px', px: 3}}
            >

                <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'flex-start', marginTop: 8}}>
                    <Titulo_Texto_Grande
                        titulo={'Distribuidora de productos naturales (Dispronan)'}
                        descripcion={'Dispronan es una compañía colombiana, ubicada en la ciudad de Bogotá, fundada por Camilo García en el año 2011. Nace de la preocupación sobre la calidad y valor nutricional del cuidado de la salud, dedicada a la comercialización de productos naturales para el cuidado de la salud, la belleza y la nutrición, brindando a sus clientes alternativas orgánicas con sus productos naturales, con el objetivo de ofrecer soluciones a los mejores precios.'}
                    />
                </Grid>


                <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'center', marginTop: 12}}>
                    <img src={logo} width={sCell ? 250 : 350} height={'auto'}/>
                </Grid>

            </Grid>


            <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'center'}}>
                <Personal/>
            </Grid>

            <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'center', marginTop: 12,marginBottom: 4}}>
                <NuestroSocio/>
            </Grid>

            <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'center', marginTop: 12}}>
                <LogosFraces/>
            </Grid>


        </Grid>
    )

}
export default Seccion_Nosotros    