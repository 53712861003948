export const obtenerValorTotal = (pedidos) => {

    let total = 0
    for (let i = 0; i < pedidos.length; i++) {
        let pedido = pedidos[i]
        let val = parseInt(pedido.valorFinal) * parseInt(pedido.cantidad)
        total = total + val
    }

    return total

}