/**************************************************
 * Nombre:       Indicadores
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     1 Hr
 **************************************************/
import {Grid, useMediaQuery} from "@mui/material";
import IndicadorRendimiento from "./Coponentes/IndicadorRendimiento";
import {theme} from "../../../Tema";

const Indicadores = ({valores, dir = 'flex-start'}) => {
    const masSM = useMediaQuery(theme.breakpoints.up("md"))

    return (
        <Grid
            container
            direction="row"
            justifyContent={dir}
            alignItems="flex-start"
            sx={{py: 2}}
            spacing={1}
        >

            {valores.map((it, index) => {
                return (
                    <IndicadorRendimiento titulo={it.titulo}
                                          valorAnterior={it.valorAnterior}
                                          valorActual={it.valorActual}
                                          detalle={it.detalle}
                                          ceparador={(index + 1) % (masSM ? 4 : 2) === 0}
                                          masSM={masSM}
                    />
                )
            })}


        </Grid>
    )

}
export default Indicadores    