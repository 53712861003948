/**************************************************
 * Nombre:       Mapa_Contactos
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     1 Hr
 **************************************************/
import {ButtonBase, Grid, Typography, useMediaQuery} from "@mui/material";
import {CONTACTOS, REDES} from "../../../Constantes";
import {theme} from "../../../Tema";

const Mapa_Contactos = () => {
    const masSM = useMediaQuery(theme.breakpoints.up('xs'))
    const sCell = useMediaQuery(theme.breakpoints.only('xs'))

    return (
        <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="flex-start"
            sx={{maxWidth: '1400px', px: 3}}
            spacing={8}
        >

            <Grid item container lg={7} sm={7} xs={12} sx={{justifyContent: 'center', marginTop: 12}}>
                <iframe
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d4531.414619013133!2d-74.08295046288406!3d4.7032203260636445!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xfda441a6af7d8221!2zNMKwNDInMTEuNiJOIDc0wrAwNCc0OS42Ilc!5e0!3m2!1ses!2sco!4v1654868902939!5m2!1ses!2sco"
                    width={'100%'} height="400" style={{border: 0, borderRadius: 10}} loading="lazy"
                    referrerPolicy="no-referrer-when-downgrade"/>
            </Grid>

            <Grid item container lg={5} sm={5} xs={11} sx={{justifyContent: 'flex-start', marginTop: sCell ? 4 : 12}}>
                <Grid
                    container
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="flex-start"
                >

                    {CONTACTOS.map((item, index) => {
                        return (
                            <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'flex-start'}}>
                                <Typography sx={{fontWeight: 600, fontSize: 18}}>{`${item.nombre} :`}
                                    <span style={{fontWeight: 400, paddingLeft: 5}}>{item.descripcion}</span>
                                </Typography>
                            </Grid>
                        )
                    })}

                    <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: sCell ? 'center' : 'flex-start', marginTop: 4}}>
                        <Typography sx={{fontWeight: 700, fontSize: 20}}>Redes Sociales</Typography>
                    </Grid>

                    <Grid item container lg={4} sm={12} xs={12} sx={{justifyContent: sCell ? 'center' : 'flex-start', marginTop: 2}}>
                        <Grid
                            container
                            direction="row"
                            justifyContent={sCell ? 'center' : "flex-start"}
                            alignItems="flex-start"
                        >
                            {REDES.map((item, index) => {
                                return (
                                    <Grid item container lg={4} sm={2} xs={2}
                                          sx={{justifyContent: sCell ? 'center' : 'flex-start'}}>
                                        <ButtonBase>
                                            <item.icono variant={'Bulk'} color={theme.palette.secondary.main}
                                                        size={'2.5rem'}/>
                                        </ButtonBase>
                                    </Grid>
                                )
                            })}


                        </Grid>
                    </Grid>

                </Grid>
            </Grid>

        </Grid>
    )

}
export default Mapa_Contactos