/**************************************************
 * Nombre:       OpcionesEnvio
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     1 Hr
 **************************************************/
import {CircularProgress, Grid, Typography, useMediaQuery} from "@mui/material";
import {useEffect, useState} from "react";
import {obtenerDaneCode} from "../../Modulo_envios/Funciones/obtenerDaneCode";
import {useCantidadesProducto} from "../Hooks/useCantidadesProducto";
import {obtenerCotizacion} from "../../Modulo_envios/Funciones/obtenerCotizacion";
import {obtenerDimencionTotal} from "../../Modulo_envios/Funciones/obtenerDimencionTotal";
import {BODEGA} from "../../Modulo_envios/Constantes_Bodega";
import {obtenerPesoTotalEnKilos} from "../../Modulo_envios/Funciones/obtenerPesoTotalEnKilos";
import SeleccionadorOpciones from "../SubComponentes/SeleccionadorOpciones";
import {escogerCotizacionEconomica} from "../../Modulo_envios/Funciones/escogerCotizacionEconomica";
import {theme} from "../../Tema";

const OpcionesEnvio = ({obtenerEntidad, props, cargando, setCargando}) => {
    const sCell = useMediaQuery(theme.breakpoints.only('xs'))
    const [opciones, setOpciones] = useState([])
    const {valorTotal, cantidadesProducto} = useCantidadesProducto()
    const [idCotizacion, setIdCotizacion] = useState('')


    const cotizarEnvio = (entidad) => {
        setCargando(true)
        let codigoDane = obtenerDaneCode(entidad.departamento, entidad.ciudad)
        let dimencionTotal = obtenerDimencionTotal(cantidadesProducto)
        let pesoTotal = obtenerPesoTotalEnKilos(cantidadesProducto)

        props.setValue('pesoTotal', pesoTotal)
        props.setValue('dimencionTotal', dimencionTotal)
        props.setValue('codigoDane', codigoDane)

        obtenerCotizacion({
            valorTotal: valorTotal,
            codigoDane: codigoDane,
            dimencionesTotal: dimencionTotal,
            bodega: BODEGA,
            direccionEnvio: entidad.direccion,
            pesoTotal: pesoTotal
        }).then((dox) => {
            if (dox.res) {
                setOpciones(dox.data)
                console.log(dox.data)
                let idRateEconomico = escogerCotizacionEconomica(dox.data)
                setIdCotizacion(idRateEconomico)
            } else {
                alert('No hay opciones de envio disponibles, por favor comunicate con informacion')
            }
            setCargando(false)
        })

    }

    useEffect(() => {

        let index = opciones.findIndex((cot) => cot.idRate === parseInt(idCotizacion))
        let opcion = opciones[index]
        if (opcion) {
            props.setValue('valorEnvio', opcion.flete)
        }
        props.setValue('idCarrier', idCotizacion)


    }, [idCotizacion])


    useEffect(() => {
        obtenerEntidad().then((entidad) => {
            cotizarEnvio(entidad)
        })
    }, []);
    return (
        <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="flex-start"
            sx={{px: sCell ? 1 : 2}}
        >

            <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'flex-end'}}>
                <Typography sx={{color: '#00000090', fontSize: 14}}>Opciones de envio</Typography>
            </Grid>

            <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'flex-start', marginTop: 4}}>
                <Grid
                    container
                    direction="row"
                    justifyContent="center"
                    alignItems="flex-start"
                    spacing={2}
                >

                    {cargando && opciones.length === 0 ?
                        <Grid
                            container
                            direction="row"
                            justifyContent="center"
                            alignItems="flex-start"
                        >

                            <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'center', marginTop: 8}}>
                                <Typography>Cotizando opciones de Envio</Typography>
                            </Grid>

                            <Grid item container lg={12} sm={12} xs={12}
                                  sx={{justifyContent: 'center', marginTop: 2, marginBottom: 8}}>
                                <CircularProgress/>
                            </Grid>

                        </Grid>

                        :

                        <SeleccionadorOpciones opciones={opciones} idCotizacion={idCotizacion}
                                               setIdCotizacion={setIdCotizacion}/>

                    }


                </Grid>
            </Grid>


        </Grid>
    )

}
export default OpcionesEnvio