export const BODEGA = {
    codigo: "11001000",
    empresa: "Dis. de productos naturales",
    nombre: "Camilo",
    apellido: "Dispronan",
    correo: "dispronan@gmail.com",
    celular: "3103102982",
    direccion: "Carrera 71G # 117b-27 ",
    adicional: "Edificio Lagartos",
    calle: "Carrera 71G",
    referencia: "N/A",
    ciudad: "Bogota",
    cc: "80201492-4",
    departamento: "Cundinamarca"

}