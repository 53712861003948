/**************************************************
 * Nombre:       Dialogo_Recibo
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     1 Hr
 **************************************************/
import {Button, CircularProgress, Grid, Typography} from "@mui/material";
import {PictureAsPdf} from "@mui/icons-material";
import {PDFDownloadLink} from "@react-pdf/renderer";
import Pdf_Recibo from "../Pdfs/Pdf_Recibo";
import {useEffect, useState} from "react";
import {obtenerDoc} from "../../Servicios/BD/obtenerDoc";
import {actualizarDoc} from "../../Servicios/BD/actualizarDoc";

const Dialogo_Recibo = ({logo, datos, referencia}) => {
    const [pedido, setPedido] = useState('')

    const obtenerMensaje = (estado) => {

        switch (estado) {
            case 1:
                return {
                    titulo: '¡Agradecemos tu compra!',
                    mensaje: 'El administrador de la tienda ya ha sido notificado y se pondra manos a la obra para despachar tu pedido, descarga el recibo y guardalo ya que es el comprobante de tu compra'
                }
            case 2:
                return {
                    titulo: 'Tu pago fue rechazado',
                    mensaje: 'No te preocupes, debe ser por falta de saldo en tu tarjeta o medio de pago, revisa tus saldos e intentalo nuevamente'
                }
            case 3:
                return {
                    titulo: 'Transaccion pendiente',
                    mensaje: 'La transaccion esta pendiente de aprobacion, tu medio de pago necesita que apruebes esta compra, revisa tu correo. Si pagaste por PSE este tarda hasta 20 min en realiza la transaccino. No te preocupes el administrador ya ha sido notificado de la compra y estara atento de cuando el pago se haga efectivo. Descarga tu comprante.'
                }
            case 4:
                return {
                    titulo: 'Transaccion fallida',
                    mensaje: 'No se culmino el flujo de creación de la transacción de manera exitosa, Revisa tu medio de pago e intentalo nuevamente'
                }
            case 11:
                return {
                    titulo: 'Transaccion Abortada',
                    mensaje: 'No se culmino el proceso de compra, no te preocupes no se ha cargado nada a tu medio de pago. No desaproveches esta oportunidad de llevar un excelente producto a tu casa, intentalo nuevamente'
                }
        }
    }


    useEffect(() => {
        let id = datos.x_extra1

        let datosNuevos = {
            pagado: datos.x_amount_ok,
            numeroAprobacion: datos.x_approval_code,
            correoPago: datos.x_customer_email,
            fechaPago: datos.x_fecha_transaccion,
            referenciaEpayco: datos.x_ref_payco,
            estadoPago: datos.x_response,
            referencia: referencia,
            nombreBanco: datos.x_bank_name
        }

        actualizarDoc('pedidos', id, datosNuevos).then((dox) => {
            if (dox.res) {
                obtenerDoc('pedidos', id).then((dox) => {
                    if (dox.res) {
                        setPedido(dox.data)

                    }
                })
            } else {

            }
        })


    }, [datos]);
    return (
        <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="flex-start"
        >

            <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'center'}}>
                <img src={logo} width={150} height={'auto'}/>
            </Grid>

            <Grid item container lg={10} sm={12} xs={12} sx={{justifyContent: 'center', marginTop: 4}}>
                <Typography
                    sx={{
                        fontSize: 24,
                        fontWeight: 800,
                        textAlign: 'center'
                    }}>{obtenerMensaje(datos.x_cod_response).titulo}</Typography>
            </Grid>

            <Grid item container lg={10} sm={10} xs={10} sx={{justifyContent: 'center', marginTop: 2}}>
                <Typography
                    sx={{
                        fontSize: 16,
                        fontWeight: 400,
                        textAlign: 'center'
                    }}>{obtenerMensaje(datos.x_cod_response).mensaje}</Typography>
            </Grid>

            {pedido ?
                <Grid item container lg={12} sm={12} xs={12}
                      sx={{justifyContent: 'center', marginTop: 4, marginBottom: 4}}>
                    <PDFDownloadLink document={<Pdf_Recibo logo={logo} datos={datos} pedido={pedido}/>}
                                     fileName={"Recibo de compra " + pedido.id}
                                     style={{textDecoration: "none"}}>
                        <Button
                            startIcon={<PictureAsPdf/>}
                        >Descargar Comprobante</Button>
                    </PDFDownloadLink>

                </Grid>

                :

                <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'center', marginTop: 4}}>
                    <CircularProgress/>
                </Grid>


            }

        </Grid>
    )

}
export default Dialogo_Recibo    