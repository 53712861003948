/**************************************************
 * Nombre:       Personal
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     1 Hr
 **************************************************/
import {Avatar, ButtonBase, Grid, Typography} from "@mui/material";
import vectorderecho from '../../../Recursos/vectorderecho.svg'
import vectorinsquierdo from '../../../Recursos/vectorizquierdo.svg'
import {Instagram, Whatsapp} from "iconsax-react";


const Personal = () => {

    return (
        <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="flex-start"
        >


            <Grid item container lg={12} sm={12} xs={12} sx={{marginTop: 14, justifyContent: "center"}}>
                <Grid
                    container
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"

                >

                    <Grid item lg={1} sm={1} xs={1}>
                        <img src={vectorderecho} width={"100%"}/>
                    </Grid>

                    <Grid item container lg={10} sm={10} xs={10} justifyContent={"center"}>
                        <Grid
                            container
                            direction="row"
                            justifyContent="center"
                            alignItems="flex-start"
                            sx={{maxWidth: "1200px"}}
                        >

                            {personas.map((it, index) => {
                                return (
                                    <Grid item container lg={5} sm={5} xs={12} sx={{justifyContent: "center"}}>

                                        <Grid
                                            container
                                            direction="row"
                                            justifyContent="flex-start"
                                            alignItems="flex-start"
                                        >

                                            <Grid item container lg={12} sm={12} xs={12}
                                                  sx={{justifyContent: "center"}}>
                                                <Avatar
                                                    sx={{width: 120, height: 120, border: 6, borderColor: "#C3C700"}}/>
                                            </Grid>

                                            <Grid item container lg={12} sm={12} xs={12}
                                                  sx={{justifyContent: "center", marginTop: 2}}>
                                                <Typography sx={{textAlign: "center", fontWeight: 600, fontSize: 20}}>Camilo
                                                    Garcia -
                                                    Dir. General</Typography>
                                            </Grid>

                                            <Grid item container lg={12} sm={12} xs={12}
                                                  sx={{justifyContent: "center", marginTop: 2}}>
                                                <Typography sx={{width: 280, textAlign: "center"}}>Establecer y
                                                    gestionar las
                                                    tareas,
                                                    las prioridades y las metas que se ha
                                                    propuesto la empresa</Typography>
                                            </Grid>

                                            <Grid item container lg={12} sm={12} xs={12}
                                                  sx={{justifyContent: "center", marginTop: 4}}>
                                                <Grid
                                                    container
                                                    direction="row"
                                                    justifyContent="center"
                                                    alignItems="flex-start"

                                                >

                                                    <Grid item container lg={2} sm={3} xs={3}
                                                          sx={{justifyContent: "center"}}>
                                                        <ButtonBase
                                                            sx={{
                                                                borderRadius: "50%",
                                                                backgroundColor: "#C3C700",
                                                                p: 1.5
                                                            }}>
                                                            <Instagram color={"#fff"} variant={"Bold"} size={"2rem"}/>
                                                        </ButtonBase>
                                                    </Grid>

                                                    <Grid item container lg={2} sm={3} xs={3}
                                                          sx={{justifyContent: "center"}}>
                                                        <ButtonBase
                                                            sx={{
                                                                borderRadius: "50%",
                                                                backgroundColor: "#C3C700",
                                                                p: 1.5
                                                            }}>
                                                            <Whatsapp color={"#fff"} variant={"Bold"} size={"2rem"}/>
                                                        </ButtonBase>
                                                    </Grid>

                                                </Grid>
                                            </Grid>

                                        </Grid>

                                    </Grid>
                                )
                            })}

                        </Grid>
                    </Grid>


                    <Grid item container lg={1} sm={1} xs={1} sx={{justifyContent: "flex-end"}}>
                        <img src={vectorinsquierdo} width={"100%"}/>
                    </Grid>
                </Grid>
            </Grid>


        </Grid>
    )

}
export default Personal


const personas = [
    {
        nombre: 'Camilo Garcia',
        descripcion: ''
    }
]