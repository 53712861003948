/**************************************************
 * Nombre:       Dialogo_Login
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     1 Hr
 **************************************************/
import {Button, ButtonBase, Grid, Typography} from "@mui/material";
import {useFormulario} from "../../Modulo_formularios/Hooks/useFormulario";
import logo from '../../Recursos/logo_oscuro.svg'
import Formulario_Login from "../Formularios/Formulario_Login";
import {theme} from "../../Tema";
import {useLoaders} from "../../Modulo_Loaders/Hooks/useLoaders";
import logo_blanco from '../../Recursos/logoblanco.svg'
import {funIngresarCorreoPass} from "../../Servicios/Auth/funIngresarCorreoPass";

const Dialogo_Login = ({datos, cerrar}) => {
    const {props, obtenerEntidad} = useFormulario({valoresDefecto: {}})
    const {Cargador, abrirCargador, cerrarCargador} = useLoaders({logo: logo_blanco})

    const ingresar = () => {

        abrirCargador('Verificando Credenciales')
        obtenerEntidad().then((entidad) => {
            funIngresarCorreoPass(entidad.correo, entidad.pass).then((dox) => {
                if (!dox.res) {
                    alert(dox.data)
                }
                cerrarCargador()
            })
        })

    }

    return (
        <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="flex-start"
            sx={{px: 4, py: 2}}
        >
            <Cargador/>

            <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'center'}}>
                <img src={logo} width={200} height={'auto'}/>
            </Grid>

            <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'center', marginTop: 6}}>
                <Formulario_Login props={props}/>
            </Grid>

            <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'center', marginTop: 4}}>
                <ButtonBase>
                    <Typography sx={{color: theme.palette.primary.main, fontWeight: 600}}>Recuperar
                        Contraseña</Typography>
                </ButtonBase>
            </Grid>

            <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'center', marginTop: 4}}>
                <Button
                    onClick={() => ingresar()}
                    sx={{py: 1}}>
                    Ingresar
                </Button>
            </Grid>


        </Grid>
    )

}
export default Dialogo_Login