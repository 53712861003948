/**************************************************
 * Nombre:       BarraCell
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     1 Hr
 **************************************************/
import {ButtonBase, Drawer, Grid, Typography, useMediaQuery} from "@mui/material";
import logo from '../../Recursos/logo_oscuro.svg'
import {useNavigate} from "react-router-dom";
import {useState} from "react";
import {HambergerMenu} from "iconsax-react";
import {SECCIONES} from "../../Constantes";
import {Close} from "@mui/icons-material";
import {useCarritoCompras} from "../../Modulo_carrito_compras/Hooks/useCarritoCompras";
import {theme} from "../../Tema";

const BarraCell = () => {
    const sCell = useMediaQuery(theme.breakpoints.only('xs'))
    const [open, setOpen] = useState(false)
    const navigate = useNavigate()
    const {BotonCarrito} = useCarritoCompras()

    const cerrar = () => {
        setOpen(false)
    }
    const abrir = () => {
        setOpen(true)
    }

    return (
        <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
            sx={{px: 3}}
        >

            <Grid item container lg={6} sm={6} xs={6} sx={{justifyContent: 'flex-start'}}>
                <img src={logo} width={120} height={'auto'}
                     onClick={() => navigate('/Home')}
                />
            </Grid>

            <Grid item container lg={6} sm={6} xs={6} sx={{justifyContent: 'flex-end'}}>
                <Grid
                    container
                    direction="row"
                    justifyContent="flex-end"
                    alignItems="flex-start"
                >

                    <Grid item container lg={4} sm={2} xs={4} sx={{justifyContent: 'flex-end'}}>
                        <BotonCarrito/>
                    </Grid>

                    <Grid item container lg={4} sm={2} xs={4} sx={{justifyContent: 'flex-end'}}>
                        <ButtonBase onClick={() => abrir()}>
                            <HambergerMenu color={"#161615"} size={"2rem"}/>
                        </ButtonBase>
                    </Grid>


                </Grid>


            </Grid>


            <Drawer
                anchor={'right'}
                open={open}
                variant={"temporary"}
                onClose={cerrar}
            >

                <Grid
                    container
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="flex-start"
                    sx={{width: 200, p: 2}}
                >

                    <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'flex-start'}}>
                        <ButtonBase onClick={() => cerrar()}>
                            <Close sx={{fill: '#fff'}}/>
                        </ButtonBase>
                    </Grid>

                    {SECCIONES.map((it, index) => {
                        return (
                            <Grid item key={`sd${it}`} container lg={12} sm={12} xs={12}
                                  sx={{justifyContent: 'center', marginTop: 2}}>
                                <ButtonBase
                                    onClick={() => {
                                        navigate('/' + it)
                                        if (sCell) {
                                            setTimeout(() => {
                                                cerrar()
                                            }, 500)
                                        }
                                    }}
                                >
                                    <Typography sx={{color: '#fff', fontSize: 20, fontWeight: 600}}>
                                        {it}
                                    </Typography>
                                </ButtonBase>
                            </Grid>
                        )
                    })}


                </Grid>

            </Drawer>


        </Grid>
    )

}
export default BarraCell