/**************************************************
 * Nombre:       ItemBarra
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     1 Hr
 **************************************************/
import {ButtonBase, Grid, Typography} from "@mui/material";
import {useLocation, useNavigate} from "react-router-dom";


const ItemBarra = ({titulo}) => {
    const navigate = useNavigate()
    const location = useLocation()

    let tit = decodeURI(location.pathname);
    let t = tit.substr(1, tit.length);


    return (
        <ButtonBase
            onClick={() => navigate("/" + titulo)}
            sx={{
                px: 3, backgroundColor: t === titulo ? "#D5680020" : "#fff", '&: hover': {
                    boxShadow:  "0px 2px 4px -1px rgba(0,0,0,0.2),0px 4px 5px 0px rgba(0,0,0,0.14),0px 1px 10px 0px rgba(0,0,0,0.12)"
                }
            }}>
            <Typography sx={{fontSize: 16, fontWeight: 500, color: "#161615"}}>{titulo}</Typography>
        </ButtonBase>
    )
}

export default ItemBarra;