/**************************************************
 * Nombre:       Titulo_Texto
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     1 Hr
 **************************************************/
import {Grid, Typography} from "@mui/material";

const Titulo_Texto_Grande = ({titulo, descripcion}) => {

    return (
        <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="flex-start"

        >
            <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="flex-start"
                sx={{maxWidth: '1400px', px: 3}}
            >

                <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'center'}}>
                    <Typography
                        sx={{fontWeight: 700, fontSize: 30, textAlign: 'center'}}
                    >{titulo}</Typography>
                </Grid>

                <Grid item container lg={8} sm={12} xs={12} sx={{justifyContent: 'center', marginTop: 4}}>
                    <Typography
                        sx={{fontWeight: 400, fontSize: 18, textAlign: 'center'}}
                    >{descripcion}</Typography>
                </Grid>


            </Grid>


        </Grid>
    )

}
export default Titulo_Texto_Grande