/**************************************************
 * Nombre:       BotonCantidad
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     1 Hr
 **************************************************/
import {Button, Grid, IconButton, Typography} from "@mui/material";
import {Add, Delete, Remove} from "@mui/icons-material";
import {Minus} from "iconsax-react";
import {useFuncionesCarrito} from "../Hooks/useFuncionesCarrito";
import {useCantidadesProducto} from "../Hooks/useCantidadesProducto";

const BotonCantidad = ({cantidad, setCantidad, item}) => {
    const {quitarProducto} = useFuncionesCarrito()
    const {borrarProducto} = useCantidadesProducto()

    const elimiarProducto = () => {
        borrarProducto(item)
        quitarProducto(item)
    }

    return (
        <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
            sx={{border: 1, borderRadius: 2, borderColor: '#00000050'}}
        >

            <Grid item container lg={4} sm={4} xs={4}
                  sx={{justifyContent: 'center', borderRight: 1, borderColor: '#00000050'}}>
                <IconButton
                    onClick={() =>
                        cantidad > 1 ?
                            setCantidad(cantidad - 1)
                            :
                            elimiarProducto()
                    }
                    sx={{p: 0.6}}>
                    {cantidad > 1 ?
                        <Remove sx={{height: 18, width: 18,}}/>
                        :
                        <Delete sx={{height: 18, width: 18}}/>
                    }

                </IconButton>
            </Grid>

            <Grid item container lg={4} sm={4} xs={4} sx={{justifyContent: 'center'}}>
                <Typography sx={{fontWeight: 600, fontSize: 14}}>{cantidad}</Typography>
            </Grid>

            <Grid item container lg={4} sm={4} xs={4}
                  sx={{justifyContent: 'center', borderLeft: 1, borderColor: '#00000050'}}>
                <IconButton
                    onClick={() => setCantidad(cantidad + 1)}
                    sx={{p: 0.6}}>
                    <Add sx={{height: 18, width: 18}}/>
                </IconButton>
            </Grid>


        </Grid>
    )

}
export default BotonCantidad