/**************************************************
 * Nombre:       Seccion_Catalogo
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     1 Hr
 **************************************************/
import {Button, Grid} from "@mui/material";
import Titulo_Texto from "./ComponentesSecciones/Titulo_Texto";
import BarraCategorias from "./ComponentesSecciones/BarraCategorias";
import Catalogo from "./ComponentesSecciones/Catalogo";
import LogosFraces from "./ComponentesSecciones/LogosFraces";
import {useParams} from 'react-router-dom'
import {useEffect, useState} from "react";
import useRecuperarColleccionListeningProg from "../../Servicios/BD/useRecuperarColleccionListeningProg";
import {where} from "firebase/firestore";
import {irArriba} from "../../Utilidades/irArriba";

const Seccion_Catalogo = () => {
    const parms = useParams()
    const [categoria, setCategoria] = useState('')
    const [wheres, setWheres] = useState([])
    const {data, cargarMas} = useRecuperarColleccionListeningProg({
        col: 'productos',
        wheres: wheres,
        orden: 'nombre'
    })


    useEffect(() => {

        irArriba()

        if (parms && parms.id) {
            let cat = parms.id.replaceAll('_', ' ')
            setCategoria(cat)
            setWheres([where('categoria', '==', cat)])
        }

    }, [parms])
    return (<Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="flex-start"
    >


        <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'center', marginTop: 4}}>
            <Titulo_Texto titulo={'Catalogo'}
                          descripcion={'\n' + 'Desde superalimentos hasta cuidado personal libre de químicos. ¡Vive en armonía con la naturaleza!'}/>
        </Grid>


        <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'flex-start', marginTop: 8}}>
            <BarraCategorias escogida={categoria}/>
        </Grid>

        <Grid item container lg={10} sm={10} xs={12} sx={{justifyContent: 'flex-start', marginTop: 12}}>
            <Catalogo data={data}/>
        </Grid>

        <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'center', marginTop: 10}}>
            <Button
                onClick={() => cargarMas()}
            >Cargar Mas</Button>
        </Grid>

        <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'flex-start', marginTop: 8}}>
            <LogosFraces/>
        </Grid>

    </Grid>)

}
export default Seccion_Catalogo    