import {Facebook, Instagram, Whatsapp} from "iconsax-react";

export const SECCIONES = ['Home', 'Catalogo', 'Nosotros', 'Contactos']

export const ESTADOS = ['nuevo', 'empacado', 'despachado', 'contactado']

export const ESTADOSPAGO = ['Aceptada', 'Rechazada', 'Pendiente', 'Fallida', 'Cancelada']

export const CONTACTOS = [
    {
        nombre: 'Nit',
        descripcion: '80201492-4'
    },
    {
        nombre: 'Direccion',
        descripcion: 'Carrera 71G # 117b-27 Bogotá'
    },
    {
        nombre: 'Celular',
        descripcion: '+57 310 310 2982'
    },
    {
        nombre: 'Celular',
        descripcion: '+57 312 368 8893'
    },
    {
        nombre: 'Correo',
        descripcion: 'Dispronan@gmail.com'
    }

]

export const REDES = [
    {
        nombre: 'facebook',
        icono: Facebook,
        url: 'https://www.facebook.com/Dispronan'
    },
    {
        nombre: 'instragam',
        icono: Instagram,
        url: 'https://www.instagram.com/dispronan/'
    },
    {
        nombre: 'whatsapp',
        icono: Whatsapp,
        url: 'https://api.whatsapp.com/send?phone=573103226276'
    },
]

export const LINKWASS = 'https://api.whatsapp.com/send?phone=573103226276'