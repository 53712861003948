/**************************************************
 * Nombre:       Propusta_Valor
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     1 Hr
 **************************************************/
import {Button, Grid, Typography, useMediaQuery} from "@mui/material";
import {theme} from "../../../Tema";
import {WhatsApp} from "@mui/icons-material";
import {useNavigate} from "react-router-dom";
import {irUrl} from "../../../Utilidades/irUrl";
import {LINKWASS} from "../../../Constantes";

const Propusta_Valor = () => {
    const masSM = useMediaQuery(theme.breakpoints.up('md'))
    const sCell = useMediaQuery(theme.breakpoints.only('xs'))
    const navegate = useNavigate()

    return (
        <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="flex-start"
        >

            <Grid
                container
                direction="row"
                justifyContent="flex-start"
                alignItems={masSM ? "center" : "flex-start"}
                sx={{maxWidth: "1400px", px: 3}}
            >


                <Grid
                    container
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="flex-start"
                >

                    <Grid item lg={12} sm={12} xs={12}>
                        <Typography component={"h1"}
                                    sx={{
                                        fontSize: masSM ? 56 : 32,
                                        fontWeight: sCell ? 700 : 800,
                                        lineHeight: 1.2,
                                        textAlign: sCell ? 'center' : 'left'
                                    }}>Distribuidora
                            de Productos
                            Naturales</Typography>
                    </Grid>

                    <Grid item lg={12} sm={12} xs={12} sx={{marginTop: 2}}>
                        <Typography component={"p"}
                                    sx={{
                                        fontSize: masSM ? 18 : 16,
                                        fontWeight: 400,
                                        textAlign: sCell ? 'center' : 'left'
                                    }}
                        >Nos dedicamos a la comercialización de productos
                            naturales. Llevamos
                            bienestar a través de nuestros productos y poder aportar buenos hábitos para
                            una vida mejor.</Typography>
                    </Grid>


                    <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'flex-start', marginTop: 4}}>
                        <Grid
                            container
                            direction="row"
                            justifyContent="flex-start"
                            alignItems="flex-start"
                            spacing={sCell ? 2 : 4}
                        >
                            <Grid item lg={5} sm={6} xs={12}>
                                <Button
                                    onClick={() => navegate('/Catalogo')}
                                    fullWidth>Ver Catalogo</Button>
                            </Grid>

                            <Grid item lg={5} sm={6} xs={12}>
                                <Button
                                    onClick={() => irUrl(LINKWASS)}
                                    startIcon={<WhatsApp/>} fullWidth variant={'outlined'}
                                        sx={{color: theme.palette.secondary.main}}>Mas infomación</Button>
                            </Grid>


                        </Grid>
                    </Grid>


                </Grid>


            </Grid>
        </Grid>
    )

}
export default Propusta_Valor