/**************************************************
 * Nombre:       Dashboard
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     1 Hr
 **************************************************/
import {Alert, Grid, Slide, Snackbar} from "@mui/material";
import DashboardBase from "../Modulo_dashboard/Componentes/DashboardBase";
import MenuLateral from "../Modulo_dashboard/Componentes/MenuLateral";
import {
    DashboardOutlined,
    Flag,
    LocalMallOutlined,
    Notifications,
    ShoppingCart,
    SwitchAccount,
    Warning
} from "@mui/icons-material";
import logo from '../Recursos/logo_oscuro.svg'
import logo_redondo from '../Recursos/logo_redondo.svg'
import logo_blanco from '../Recursos/logoblanco.svg'
import {useContext, useEffect, useState} from "react";
import {usuarioContext} from "../App";
import Seccion_Catalogo from "./Secciones/Seccion_Catalogo";
import Seccion_Categorias from "./Secciones/Seccion_Categorias";
import {funSalir} from "../Servicios/Auth/funSalir";
import Seccion_Potenciales from "./Secciones/Seccion_Potenciales";
import Seccion_Pqrs from "./Secciones/Seccion_Pqrs";
import Seccion_Pedidos from "./Secciones/Seccion_Pedidos";
import {useGetToken} from "../Modulo_Notificaciones/Hooks/useGetToken";
import {obtenerCol} from "../Servicios/BD/obtenerCol";
import {agregarItemArrayDoc} from "../Servicios/BD/agregarItemArrayDoc";
import {useEscucharNotificacion} from "../Modulo_Notificaciones/Hooks/useEscucharNotificacion";

const Dashboard = () => {
    const {usuario} = useContext(usuarioContext)
    const {token} = useGetToken()
    const {Notificador} = useEscucharNotificacion()
    const [open,setOpen] = useState(false)

    useEffect(() => {


        if (usuario && usuario.id && token) {
            obtenerCol('tokens', 'tokens').then((dox) => {
                if (dox.res && dox.data) {
                    let arrTokens = dox.data
                    let index = arrTokens.findIndex((e) => e === token)

                    if (index >= 0) {
                        setOpen(true)
                    }else{
                        agregarItemArrayDoc('tokens', 'tokens', 'arrTokens', token).then((des) => {
                            if (des.res) {
                                // console.log('Token actualizado', token)
                                setOpen(true)
                            }
                        })
                    }
                }
            })
        }

    }, [usuario, token]);
    return (
        <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="flex-start"
        >

            <Notificador/>

            <DashboardBase secciones={secciones}>
                <MenuLateral
                    logo={logo}
                    //clickPerfil={click}
                    logoRedondo={logo_redondo}
                    logoBlanco={logo_blanco}
                    usuario={usuario}
                    salir={funSalir}
                />
            </DashboardBase>

            <Snackbar
                open={open}
                autoHideDuration={3000}
                onClose={() => setOpen(false)}
                anchorOrigin={{vertical: 'top', horizontal: 'right'}}
                TransitionComponent={Slide}
                //action={action}
                role={'alert'}
                disableWindowBlurListener={true}

            >
                <Alert
                    icon={<Notifications fontSize="inherit"/>}
                    onClose={() => setOpen(false)}
                    severity={'secondary'}
                    variant={'filled'}
                    sx={{width: '100%'}}
                >
                    {'Notificaciones Encendidas'}
                </Alert>
            </Snackbar>


        </Grid>
    )

}
export default Dashboard

const secciones = [

    {
        nombre: 'Pedidos',
        Componente: <Seccion_Pedidos/>,
        icono: ShoppingCart,
        categoria: 'propia',
        camino: '/',
    },

    {
        nombre: 'Catalogo',
        Componente: <Seccion_Catalogo/>,
        icono: LocalMallOutlined,
        categoria: 'Tienda',
        camino: '/Catalogo',
    },
    {
        nombre: 'Categorias',
        Componente: <Seccion_Categorias/>,
        icono: Flag,
        categoria: 'Tienda',
        camino: '/Categorias',
    },
    {
        nombre: 'Potenciales',
        Componente: <Seccion_Potenciales/>,
        icono: SwitchAccount,
        categoria: 'Formularios',
        camino: '/Potenciales',
    },
    {
        nombre: 'Pqrs',
        Componente: <Seccion_Pqrs/>,
        icono: Warning,
        categoria: 'Formularios',
        camino: '/Pqrs',
    },

]