/**************************************************
 * Nombre:       Seccion_Categorias
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     12 Hr
 **************************************************/
import {Button, Grid, useMediaQuery} from "@mui/material";
import {useObtenerColleccionTabla} from "../../Servicios/BD/useObtenerColleccionTabla";
import {useEffect, useState} from "react";
import {useDialogo} from "../../Modulo_dialogos/Hooks/useDialogo";
import {Flag, LocalMall, NoteAltOutlined} from "@mui/icons-material";
import {contarPropiedad} from "../../Servicios/BD/Contadores/contarPropiedad";
import TituloNotificaciones from "../../Modulo_dashboard/ComponentesSecundarios/TituloNotificaciones";
import TablaBasica from "../../Modulo_tablas/Componentes/TablaBasica";
import Dialogo_Producto from "../Dialogos/Dialogo_Producto";
import Indicadores from "../../Modulo_Graficas/Componentes/Indicadores/Indicadores";
import {contarColeccion} from "../../Servicios/BD/Contadores/contarColeccion";
import {theme} from "../../Tema";
import {CATEGORIAS} from "../../Constantes";
import Dialogo_Categoria from "../Dialogos/Dialogo_Categoria";
import Dialogo_Potenciales from "../Dialogos/Dialogo_Potenciales";

const Seccion_Potenciales = () => {
    const sCell = useMediaQuery(theme.breakpoints.only('xs'))
    const [numProductos, setNumProductos] = useState(0)
    const [recargar, setRecargar] = useState(false)
    const {props,cargarNuevamente} = useObtenerColleccionTabla({
        coleccion: 'sociosPotenciales',
        filtroInicial: 'nombre',
    })
    const {Dialogo, abrir} = useDialogo({Componente: Dialogo_Potenciales, realizado: () => setRecargar(!recargar)})


    const botones = [
        <Button
            color={'primary'}
            onClick={() => abrir()}
            startIcon={<Flag/>}
            variant={'contained'}>
            Nueva
        </Button>
    ]


    useEffect(() => {

        contarColeccion({coleccion: 'sociosPotenciales'}).then((dox) => {
            if (dox.res) {
                setNumProductos(dox.data)
                console.log(dox.data)
            }
        })

        cargarNuevamente()

    }, [recargar]);
    return (
        <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="flex-start"
        >

            <Dialogo/>

            <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'flex-start'}}>
                <TituloNotificaciones titulo={'Potenciales'} textoComplementario={'potenciales'}
                                      textoResaltado={`${numProductos} Socios`} botones={botones}/>
            </Grid>

            <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'flex-start', marginTop: 5}}>
                <TablaBasica
                    filtros={filtros}
                    datosCabecera={datosCabecera}
                    {...props}
                    click={abrir}
                />
            </Grid>


        </Grid>
    )

}
export default Seccion_Potenciales

const filtros = [
    {
        nombre: 'Nombre',
        propiedad: 'nombre',

    },
    {
        nombre: 'Mas Reciente',
        propiedad: 'fecha',
        direccion: 'desc'

    }
]

const datosCabecera = [
    {
        nombre: 'Fecha',
        direccion: 'left',
        propiedad: 'fecha',
        tipo: 'fecha'

    },
    {
        nombre: 'Nombre',
        direccion: 'left',
        propiedad: 'nombre',

    },
    {
        nombre: 'Celular',
        direccion: 'left',
        propiedad: 'celular',

    },
    {
        nombre: 'Ciudad',
        direccion: 'right',
        propiedad: 'ciudad',

    }
]


