/**************************************************
 * Nombre:       BarraPc
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     1 Hr
 **************************************************/
import {Button, Grid} from "@mui/material";
import ItemBarra from "./Componentes/ItemBarra";
import logo from '../../Recursos/logo_oscuro.svg'
import {useNavigate} from 'react-router-dom'
import {SECCIONES} from "../../Constantes";
import {useCarritoCompras} from "../../Modulo_carrito_compras/Hooks/useCarritoCompras";
import Tarjeta_Producto from "../../Modulo_carrito_compras/Tarjetas/Tarjeta_Producto";

const BarraPc = () => {
    const navigate = useNavigate()
    const {BotonCarrito} = useCarritoCompras()

    return (
        <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="flex-start"

        >

            <Grid
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="center"
                sx={{maxWidth: "1400px", px: 3, py: 1, marginTop: 1}}
            >

                <Grid item lg={5} sm={2} xs={4} onClick={() => navigate("/")}>
                    <img src={logo} width={150} height={'auto'}
                         onClick={() => navigate('/Home')}
                    />
                </Grid>


                <Grid item container lg={7} sm={10} xs={12} sx={{justifyContent: "flex-end"}}>
                    <Grid
                        container
                        direction="row"
                        justifyContent="space-between"
                        alignItems="center"
                    >

                        {SECCIONES.map((it, index) => {
                            return (
                                <Grid key={`sx-${it}`} item container lg={2} sm={2} xs={12}
                                      sx={{justifyContent: "flex-end"}}>
                                    <ItemBarra titulo={it}/>
                                </Grid>
                            )
                        })}


                        <Grid item container lg={2} sm={4} xs={12} sx={{justifyContent: "flex-end"}}>
                            <BotonCarrito/>
                        </Grid>

                    </Grid>
                </Grid>


            </Grid>


        </Grid>
    )

}
export default BarraPc