/**************************************************
 * Nombre:       Pdf_Recibo
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     1 Hr
 **************************************************/
import {Page, Text, View, Document, StyleSheet, Font, Image, Svg} from '@react-pdf/renderer';
import f500 from '../Recursos/Montserrat-Regular.ttf'
import f600 from '../Recursos/Montserrat-Medium.ttf'
import f700 from '../Recursos/Montserrat-Bold.ttf'
import {daDK} from "@mui/material/locale";
import {formatoMoneda} from "../Utilidades/formatoMoneda";

const Pdf_Recibo_Dashboard = ({logo, pedido}) => {
    Font.register({family: '500', src: f500})
    Font.register({family: '600', src: f600})
    Font.register({family: '700', src: f700})


    const styles = StyleSheet.create({
        page: {
            flexDirection: 'row',
            backgroundColor: '#FFF',
            padding: 28
        },
        gitc: {
            justifyContent: 'center',
            flexDirection: 'row',
            width: '100%'
        },
        git: {
            justifyContent: 'flex-start',
            flexDirection: 'row',
            width: '100%'
        },
        gitg: {
            justifyContent: 'flex-end',
            flexDirection: 'row',
            width: '100%'
        },
        texto: {
            fontSize: 8,
            fontFamily: '600'
        },
        textoL: {
            fontSize: 8,
            fontFamily: '500'
        },
        textoGT: {
            fontSize: 10,
            fontFamily: '700'
        },
        textoG: {
            fontSize: 10,
            fontFamily: '500'
        },


    });

    const Titulos = ({derecha, izquierda}) => {
        return (
            <View style={styles.gitc}>
                <View style={{...styles.git, width: '50%'}}>
                    <Text style={styles.textoGT}>{derecha}</Text>
                </View>
                <View style={{...styles.git, width: '50%', justifyContent: 'flex-end'}}>
                    <Text style={styles.textoGT}>{izquierda}</Text>
                </View>
            </View>
        )
    }

    const Datos = ({derecha, izquierda}) => {
        return (
            <View style={styles.gitc}>
                <View style={{...styles.git, width: '50%'}}>
                    <Text style={styles.textoG}>{derecha}</Text>
                </View>
                <View style={{...styles.git, width: '50%', justifyContent: 'flex-end'}}>
                    <Text style={styles.textoG}>{izquierda}</Text>
                </View>
            </View>
        )
    }

    const Items = ({items = []}) => {

        return items.map((it, index) => {
            return (
                <View style={styles.gitc}>
                    <View style={{...styles.git, width: '50%'}}>
                        <Text style={styles.textoG}>{`( ${it.cantidad} ) ${it.nombre}`}</Text>
                    </View>
                    <View style={{...styles.git, width: '50%', justifyContent: 'flex-end'}}>
                        <Text style={styles.textoG}>{formatoMoneda(it.cantidad * it.valorFinal)}</Text>
                    </View>
                </View>
            )
        })
    }


    return (
        <Document>
            <Page size={'C6'} style={styles.page}>
                <View style={{justifyContent: 'flex-start', flexDirection: 'column', width: '100%'}}>
                    <View style={styles.gitc}>
                        <Image src={logo} style={{width: 110, height: 50}}/>
                    </View>

                    <View style={{...styles.gitc, marginTop: 10}}>
                        <Text style={styles.textoG}>80201492-4</Text>
                    </View>
                    <View style={{...styles.gitc, marginTop: 0}}>
                        <Text style={styles.textoG}>Carrera 71G # 117b-27 Bogota</Text>
                    </View>

                    <View style={{...styles.gitc, marginTop: 0}}>
                        <Text style={styles.textoG}>312 368 8893</Text>
                    </View>

                    <View style={{...styles.git, marginTop: 20}}>
                        <Datos derecha={'Fecha de Pago'} izquierda={pedido.fechaPago}/>
                    </View>

                    <View style={{...styles.git, marginTop: 10}}>
                        <Titulos derecha={'Ref Pago'} izquierda={pedido.referenciaEpayco}/>
                    </View>

                    <View style={styles.git}>
                        <Titulos derecha={'Estado'} izquierda={pedido.estadoPago}/>
                    </View>

                    <View style={styles.git}>
                        <Datos derecha={'Banco'} izquierda={pedido.nombreBanco}/>
                    </View>

                    <View style={styles.git}>
                        <Datos derecha={'Numero Aprobacion'} izquierda={pedido.numeroAprobacion}/>
                    </View>


                    <View style={styles.git}>
                        <Datos derecha={'Id Pedido'} izquierda={pedido.id}/>
                    </View>


                    <View style={{...styles.gitc, marginTop: 20}}>
                        <Text
                            style={styles.textoG}>*******************************************************************</Text>
                    </View>


                    <View style={styles.git}>
                        <Titulos derecha={'Descripcion'} izquierda={'Valor'}/>
                    </View>

                    <View style={{...styles.git, flexDirection: 'column'}}>
                        <Items items={pedido.productos}/>
                    </View>

                    <View style={{...styles.gitc, marginTop: 6}}>
                        <Text
                            style={styles.textoG}>*******************************************************************</Text>
                    </View>

                    <View style={styles.git}>
                        <Titulos derecha={'Envio'} izquierda={formatoMoneda(pedido.valorEnvio)}/>
                    </View>

                    <View style={{...styles.gitc, marginTop: 6}}>
                        <Text
                            style={styles.textoG}>*******************************************************************</Text>
                    </View>

                    <View style={styles.git}>
                        <Titulos derecha={'Total Pagado'} izquierda={formatoMoneda(pedido.pagado)}/>
                    </View>

                    <View style={{...styles.gitc, marginTop: 40}}>
                        <Text style={styles.textoG}>¡Muchas gracias por tu compra!</Text>
                    </View>


                </View>
            </Page>
        </Document>
    )

}
export default Pdf_Recibo_Dashboard