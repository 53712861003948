/**************************************************
 * Nombre:       Pqrs_Formulario
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     1 Hr
 **************************************************/
import {Grid, Typography} from "@mui/material";
import {useFormulario} from "../../../Modulo_formularios/Hooks/useFormulario";
import Formulario_Pqrs from "../../Formularios/Formulario_Pqrs";
import {LoadingButton} from "@mui/lab";
import {useLoaders} from "../../../Modulo_Loaders/Hooks/useLoaders";
import logo from "../../../Recursos/logo_redondo.svg";
import {guardarDoc} from "../../../Servicios/BD/guardarDoc";

const Pqrs_Formulario = () => {
    const {props, limpiarEntidad, obtenerEntidad} = useFormulario({valoresDefecto: {tipo: 'Peticion'}})
    const {Cargador, abrirCargador, cerrarCargador} = useLoaders({logo: logo})

    const guardar = () => {
        abrirCargador('Enviando Datos')
        obtenerEntidad().then((entidad) => {
            let obj = entidad
            obj.fecha = new Date().getTime()
            obj.estado = 'nueva'
            guardarDoc('pqrss', obj).then((dox) => {
                if (dox.res) {
                    limpiarEntidad()
                    cerrarCargador()
                    alert('Datos enviados con exito')
                } else {
                    alert('Ups paso algo inesperado, intentalo nuevamente')
                }
                cerrarCargador()
            })
        })

    }

    return (
        <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="flex-start"
        >
            <Cargador/>

            <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="flex-start"
                sx={{maxWidth: '1400px', px: 2}}
            >

                <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'center'}}>
                    <Typography sx={{fontSize: 34, fontWeight: 700}}>PQRS</Typography>
                </Grid>

                <Grid item container lg={8} sm={8} xs={12} sx={{justifyContent: 'center', marginTop: 2}}>
                    <Typography sx={{fontSize: 18, fontWeight: 400, textAlign: 'center'}}>
                        Para nosotros en muy importante saber lo que nuestros clientes y aliados piensan asi que por
                        favor
                        deja aqui tus peticiones, quejas, reclamos y sugerencias.
                    </Typography>
                </Grid>

                <Grid item container lg={6} sm={8} xs={12} sx={{justifyContent: 'flex-start', marginTop: 8}}>
                    <Formulario_Pqrs props={props}/>
                </Grid>

                <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'center', marginTop: 4}}>
                    <LoadingButton
                        onClick={() => guardar()}
                        variant={'contained'}
                        color={'secondary'}
                    >
                        Enviar Informacion
                    </LoadingButton>
                </Grid>


            </Grid>


        </Grid>
    )

}
export default Pqrs_Formulario    