/**************************************************
 * Nombre:       Formulario_Pqrs
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     1 Hr
 **************************************************/
import {Grid} from "@mui/material";
import IngresoTexto from "../../Modulo_formularios/Componentes/IngresoTexto";
import {AccountCircle, Email} from "@mui/icons-material";
import IngresoSelect from "../../Modulo_formularios/Componentes/IngresoSelect";
import IngresoCheckGrupo from "../../Modulo_formularios/Componentes/IngresoCheckGrupo";

const Formulario_Pqrs = ({...props}) => {

    return (
        <form noValidate style={{width: '100%'}}>
            <Grid
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="flex-start"
                spacing={2}
            >

                <Grid item container lg={6} sm={6} xs={12} sx={{justifyContent: 'flex-start'}}>
                    <IngresoTexto Icono={AccountCircle} nombre={'Nombre'} dato={'nombre'}  {...props}
                                  requerido={'El nombre es requerido*'}/>
                </Grid>

                <Grid item container lg={6} sm={6} xs={12} sx={{justifyContent: 'flex-start'}}>
                    <IngresoTexto Icono={Email} nombre={'Correo'} dato={'correo'}  {...props}
                                  requerido={'El correo es requerido*'}/>
                </Grid>

                <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'flex-start'}}>
                    <IngresoTexto nombre={'Mensaje'} dato={'mensaje'}  {...props} lineas={6}
                    />
                </Grid>

                <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'flex-start'}}>
                    <IngresoCheckGrupo nombre={''} dato={'tipo'}
                                       opciones={['Peticion', 'Queja', 'Reclamo', 'Sugerencia']} {...props} />
                </Grid>


            </Grid>
        </form>
    )

}
export default Formulario_Pqrs