/**************************************************
 * Nombre:       BarraCategorias
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     1 Hr
 **************************************************/
import {useEffect, useState} from 'react';
import {ButtonBase, Grid, Typography, useMediaQuery} from "@mui/material";
import {theme} from "../../../Tema";
import {obtenerCol} from "../../../Servicios/BD/obtenerCol";
import {useNavigate} from 'react-router-dom'


const BarraCategorias = ({escogida = ''}) => {
    const masSM = useMediaQuery(theme.breakpoints.up('xs'))
    const sCell = useMediaQuery(theme.breakpoints.only('xs'))
    const [categorias, setCategorias] = useState([])
    const navigate = useNavigate()

    useEffect(() => {
        obtenerCol('categorias').then((dox) => {
            if (dox.res) {
                let arr = dox.data.map((it) => it.nombre)
                setCategorias(arr)
            }
        })
    }, [])
    return (
        <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="flex-start"
            sx={{backgroundColor: "#C3C700"}}
        >

            <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="flex-start"
                sx={{maxWidth: "1400px", px: 2, py: 2}}
                spacing={sCell ? 3 : 4}
            >

                {categorias.map((item, index) => {
                    return (
                        <Grid key={`ser${index}`} item>
                            <ButtonBase
                                onClick={() => navigate('/Catalogo/' + item.replaceAll(' ', '_'))}
                            >
                                <Typography sx={{
                                    color: escogida === item ? theme.palette.primary.main : '#fff',
                                    fontWeight: 600,
                                    fontSize: 20
                                }}>
                                    {item}
                                </Typography>
                            </ButtonBase>
                        </Grid>

                    )
                })}

            </Grid>
        </Grid>
    )
}

export default BarraCategorias;



