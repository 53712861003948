import axios from "axios";

export const generarGuia = ({bodega, valorTotal, pedido}) => {


    let data = JSON.stringify({
        "idRate": pedido.idCarrier,
        "myShipmentReference": pedido.id,
        "requestPickup": false,
        "pickupDate": "2024-11-24",
        "insurance": true,
        "description": "Productos Naturales",
        "contentValue": valorTotal,
        "conValue": valorTotal,
        "includeGuideCost": false,
        "codPaymentMethod": "cash",
        "packages": [
            {
                "weight": pedido.pesoTotal,
                "height": pedido.dimencionTotal.alto,
                "width": pedido.dimencionTotal.ancho,
                "length": pedido.dimencionTotal.fondo
            }
        ],
        "origin": {
            "company": 'Dispronan',
            "firstName": bodega.nombre,
            "lastName": bodega.apellido,
            "email": bodega.correo,
            "phone": bodega.celular,
            "address": bodega.direccion,
            "suburb": bodega.adicional,
            "crossStreet": bodega.calle,
            "reference": bodega.referencia,
            "daneCode": bodega.codigo
        },
        "destination": {
            "company": "NA",
            "firstName": pedido.nombre.substr(0, pedido.nombre.length > 14 ? 13 : pedido.nombre.length),
            "lastName": pedido.apellido.substr(0, pedido.apellido.length > 14 ? 13 : pedido.apellido.length),
            "email": pedido.correo,
            "phone": pedido.celular.replaceAll(' ', ''),
            "address": pedido.direccion.substr(0, pedido.direccion.length > 50 ? 49 : pedido.direccion.length),
            "suburb": pedido.barrio,
            "crossStreet": ' Banjales',
            "reference": pedido.indicaciones.substr(0, pedido.indicaciones.length > 25 ? 24 : pedido.indicaciones.length),
            "daneCode": pedido.codigoDane + '000'
        }
    });

    /*
    *
    * "company": "Luis M Rosero Desarrollador",
            "firstName": "Luis",
            "lastName": "Rosero",
            "email": "luismrosero1992@gmail.com",
            "phone": "3004627579",
            "address": "Mz 9 Cs 8 ",
            "suburb": "Pinares de Santa Ana",
            "crossStreet": "Carrera 3",
            "reference": "Casa esquinera",
            "daneCode": "52356000"
    *
    *
    *
    * */



    let config = {
        method: 'post',
        maxBodyLength: Infinity,
        url: 'https://api.envioclickpro.com.co/api/v2/shipment',
        headers: {
            'Authorization': '368231a0-a3ef-4d4c-ab26-dfb9bc8a3727',
            'Content-Type': 'application/json',
           // 'Cookie': 'AWSALB=bwrxrVFlOjToH03feu6aWP1e44WzSh2XkE65RavFIksQg0f1sr05k6qu8qCoAzIdD21Qr8no+MXrayYQ4SeyVTayfGdqkY7vN6WIO85GwpoVcI4b3ZYN40+nqLe6; AWSALBCORS=bwrxrVFlOjToH03feu6aWP1e44WzSh2XkE65RavFIksQg0f1sr05k6qu8qCoAzIdD21Qr8no+MXrayYQ4SeyVTayfGdqkY7vN6WIO85GwpoVcI4b3ZYN40+nqLe6'
        },
        data: data
    };


    return new Promise((resolve) => {
        console.log(data)

        axios(config)
            .then(function (response) {
                //console.log(response)
                if (response.status === 200) {
                    return resolve({res: true, data: response.data.data})
                } else {
                    return resolve({res: false, data: "error interno"})
                }
            })
            .catch(function (error) {
                console.log(error)
                return resolve({res: false, data: error.message})
            });

    })

}