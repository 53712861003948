/**************************************************
 * Nombre:       Pagina
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     1 Hr
 **************************************************/
import {Grid} from "@mui/material";
import {BrowserRouter as Router, Route, Routes} from 'react-router-dom';
import Home from "./Secciones/Home";
import Barra from "./Barra/Barra";
import Footer from "./Footer";
import fondo from '../Recursos/fondo.svg'
import Seccion_Catalogo from "./Secciones/Seccion_Catalogo";
import Seccion_Nosotros from "./Secciones/Seccion_Nosotros";
import Seccion_Contactos from "./Secciones/Seccion_Contactos";
import Detalle_Producto from "./Secciones/Detalle_Producto";

const Pagina = () => {


    return (
        <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="flex-start"
            sx={{backgroundImage: `url(${fondo})`}}
        >
            <Router>

                <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: "center", marginTop: 2}}>
                    <Barra/>
                </Grid>

                <Routes>

                    <Route path={"/"} element={<Home/>}/>
                    <Route path={"/Home"} element={<Home/>}/>
                    <Route path={"/*"} element={<Home/>}/>
                    <Route path={"/Catalogo"} element={<Seccion_Catalogo/>}/>
                    <Route path={"/Catalogo/:id"} element={<Seccion_Catalogo/>}/>
                    <Route path={"/Nosotros"} element={<Seccion_Nosotros/>}/>
                    <Route path={"/Contactos"} element={<Seccion_Contactos/>}/>
                    <Route path={"/Detalle/:id"} element={<Detalle_Producto/>}/>

                </Routes>

                <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: "center"}}>
                    <Footer/>
                </Grid>


            </Router>


        </Grid>
    )

}
export default Pagina    