/**************************************************
 * Nombre:       Home_FraseHumanizadora
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     1 Hr
 **************************************************/
import {React} from 'react';
import {Button, Grid, Typography, useMediaQuery} from "@mui/material";
import vectorderecho from '../../../Recursos/vectorderecho.svg'
import vectorinzquierdo from '../../../Recursos/vectorizquierdo.svg'
import logo from '../../../Recursos/logo_oscuro.svg'

import {theme} from "../../../Tema";

const FraseHumanizadora = () => {
    const masSM = useMediaQuery(theme.breakpoints.up("md"))
    const sCell = useMediaQuery(theme.breakpoints.only("xs"))

    return (
        <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="flex-start"

        >


            <Grid item lg={12} sm={12} xs={12} sx={{marginTop: 8}}>
                <Grid
                    container
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="center"
                >

                    <Grid item lg={1} sm={1} xs={1}>
                        <img src={vectorderecho} width={"100%"}/>
                    </Grid>

                    <Grid item container lg={10} sm={10} xs={10} sx={{justifyContent: "center"}}>
                        <Grid
                            container
                            direction="row"
                            justifyContent="center"
                            alignItems="flex-start"
                        >

                            <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: "center"}}>

                                <Typography sx={{
                                    fontSize: 28,
                                    fontWeight: 700,
                                    color: "#161615",
                                    textAlign: 'center',
                                    lineHeight: 1.2
                                }}>
                                    Saber comer es saber vivir
                                </Typography>
                            </Grid>

                            <Grid container
                                  sx={{justifyContent: "center", maxWidth: sCell ? "300px" : "400px", marginTop: 2}}>
                                <Typography sx={{
                                    fontSize: sCell ? 18 : 20,
                                    fontWeight: 400,
                                    color: "#161615",
                                    textAlign: "center",
                                    lineHeight: 1.4
                                }}>
                                    Un hombre demasiado ocupado para cuidar de su salud es como un mecánico demasiado
                                    ocupado como para cuidar sus herramientas.
                                </Typography>
                            </Grid>


                            <Grid item container lg={12} sm={12} xs={12}
                                  sx={{justifyContent: "center", marginTop: masSM ? -30 : -18}}>
                                <img src={logo} width={"50%"} style={{opacity: 0.1}}/>
                            </Grid>

                        </Grid>

                    </Grid>

                    <Grid item container lg={1} sm={1} xs={1} sx={{justifyContent: "flex-end"}}>
                        <img src={vectorinzquierdo} width={"100%"}/>
                    </Grid>

                </Grid>

            </Grid>


        </Grid>
    )
}

export default FraseHumanizadora;