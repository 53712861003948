/**************************************************
 * Nombre:       Tarjeta_Anuncio
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     1 Hr
 **************************************************/
import {ButtonBase, Grid, Typography, useMediaQuery} from "@mui/material";
import {theme} from "../../../Tema";
import {useEffect, useState} from "react";
import {obtenerDoc} from "../../../Servicios/BD/obtenerDoc";
import {useNavigate} from 'react-router-dom'

const Tarjeta_Anuncio = () => {
    const sCell = useMediaQuery(theme.breakpoints.only('xs'))
    const masSM = useMediaQuery(theme.breakpoints.up('md'))
    const [producto, setProducto] = useState({})
    const navigate = useNavigate()

    useEffect(() => {
        obtenerDoc('anuncio', 'anuncio').then((dox) => {
            if (dox.res) {
                setProducto(dox.data)
            }
        })
    }, [])
    return (
        <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
            spacing={2}
            sx={{px: 4}}
            //  onClick={() => navigate("/Detalle/" + data.idProducto)}
        >


            <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'flex-start'}}>
                <Grid
                    container
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="flex-start"
                    spacing={5}
                >

                    <Grid item container lg={7} sm={12} xs={12} sx={{justifyContent: 'flex-start'}}>
                        <Grid
                            container
                            direction="row"
                            justifyContent="center"
                            alignItems="flex-start"

                        >


                            <Grid item container lg={12} sm={12} xs={12}
                                  sx={{
                                      justifyContent: 'flex-start',
                                      zIndex: 1,
                                      border: 10,
                                      borderColor: '#C3C700',
                                      borderRadius: 5
                                  }}>
                                <img src={producto.img} width={'100%'} height={'auto'}
                                     style={{borderRadius: 4}}
                                />
                            </Grid>

                        </Grid>

                    </Grid>

                    <Grid item container lg={5} sm={12} xs={12} sx={{justifyContent: 'flex-start'}}>
                        <Grid
                            container
                            direction="row"
                            justifyContent="flex-start"
                            alignItems="flex-start"
                        >

                            <Grid item lg={12} sm={12} xs={12}>
                                <Typography component={"h1"}
                                            sx={{
                                                fontSize: 24,
                                                fontWeight: 700,
                                                lineHeight: 1.3
                                            }}>{producto.nombre}
                                </Typography>
                            </Grid>


                            <Grid item lg={12} sm={12} xs={12} sx={{marginTop: 2}}>
                                <Typography
                                    sx={{
                                        fontSize: 16,
                                        fontWeight: 400,
                                        lineHeight: 1.4,
                                        display: '-webkit-box',
                                        overflow: 'hidden',
                                        WebkitBoxOrient: 'vertical',
                                        WebkitLineClamp: 8,
                                        textOverflow: "ellipsis",
                                    }}>
                                    {producto.descripcion}
                                </Typography>
                            </Grid>


                            <Grid item container lg={12} sm={12} xs={12}
                                  sx={{justifyContent: "flex-start", marginTop: 2}}>
                                <ButtonBase sx={{px: 0}}
                                            onClick={() => navigate("/Detalle/" + producto.idProducto)}
                                >
                                    <Typography
                                        sx={{
                                            color: "#D56800",
                                            fontSize: 16,
                                            fontWeight: 600,
                                            textDecoration: "underline",
                                            '&hover:': {
                                                boxShadow: 0
                                            }
                                        }}>
                                        Ver Descuento especial
                                    </Typography>
                                </ButtonBase>

                            </Grid>


                        </Grid>
                    </Grid>

                </Grid>
            </Grid>


            <Grid item lg={12} sm={12} xs={12} sx={{marginTop: 1}}>
                <Grid
                    container
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="flex-start"
                >

                    {producto && producto.beneficio1 &&

                        <Grid item lg={12} sm={12} xs={12} sx={{marginTop: 0}}>
                            <Typography
                                sx={{
                                    fontWeight: 400,
                                    fontSize: 18,
                                    color: '#000000',
                                    display: '-webkit-box',
                                    overflow: 'hidden',
                                    WebkitBoxOrient: 'vertical',
                                    WebkitLineClamp: 1,
                                    textOverflow: "ellipsis",
                                }}>


                                {"• " + producto.beneficio1}</Typography>
                        </Grid>
                    }

                    {producto && producto.beneficio2 &&
                        <Grid item lg={12} sm={12} xs={12} sx={{marginTop: 0}}>
                            <Typography
                                sx={{
                                    fontWeight: 400,
                                    fontSize: 16,
                                    color: '#00000090',
                                    display: '-webkit-box',
                                    overflow: 'hidden',
                                    WebkitBoxOrient: 'vertical',
                                    WebkitLineClamp: 1,
                                    textOverflow: "ellipsis",
                                }}>


                                {"• " + producto.beneficio2}</Typography>
                        </Grid>
                    }

                    {producto && producto.beneficio3 &&

                        <Grid item lg={12} sm={12} xs={12} sx={{marginTop: 0}}>
                            <Typography
                                sx={{
                                    fontWeight: 400,
                                    fontSize: 16,
                                    color: '#00000090',
                                    display: '-webkit-box',
                                    overflow: 'hidden',
                                    WebkitBoxOrient: 'vertical',
                                    WebkitLineClamp: 1,
                                    textOverflow: "ellipsis",
                                }}>


                                {"• " + producto.beneficio3}</Typography>
                        </Grid>
                    }

                    {producto && producto.beneficio4 &&
                        <Grid item lg={12} sm={12} xs={12} sx={{marginTop: 0}}>
                            <Typography
                                sx={{
                                    fontWeight: 400,
                                    fontSize: 16,
                                    color: '#00000090',
                                    display: '-webkit-box',
                                    overflow: 'hidden',
                                    WebkitBoxOrient: 'vertical',
                                    WebkitLineClamp: 1,
                                    textOverflow: "ellipsis",
                                }}>
                                {"• " + producto.beneficio4}</Typography>
                        </Grid>
                    }


                </Grid>

            </Grid>


        </Grid>
    )

}
export default Tarjeta_Anuncio

const beneficios = [
    'Mejora la eficiencia cardica y los latidos del corazon',
    'Ideal para curar el enrojecimiento de la piel',
    'Mejora la recuperacion de los deportistas'
]