/**************************************************
 * Nombre:       NuestroSocio
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     1 Hr
 **************************************************/
import {Grid, Typography} from "@mui/material";
import {useFormulario} from "../../../Modulo_formularios/Hooks/useFormulario";
import Formulario_socio from "../../Formularios/Formulario_socio";
import {LoadingButton} from "@mui/lab";
import {useLoaders} from "../../../Modulo_Loaders/Hooks/useLoaders";
import logo from '../../../Recursos/logo_redondo.svg'
import {guardarDoc} from "../../../Servicios/BD/guardarDoc";

const NuestroSocio = () => {
    const {obtenerEntidad, props, setEntidad, limpiarEntidad} = useFormulario({valoresDefecto: {}})
    const {Cargador, abrirCargador, cerrarCargador} = useLoaders({logo: logo})

    const guardar = () => {
        abrirCargador('Enviando Datos')
        obtenerEntidad().then((entidad) => {
            let obj = entidad
            obj.fecha = new Date().getTime()
            guardarDoc('sociosPotenciales', obj).then((dox) => {
                if (dox.res) {
                    limpiarEntidad()
                    cerrarCargador()
                    alert('Datos enviados con exito')
                } else {
                    alert('Ups paso algo inesperado, intentalo nuevamente')
                }
                cerrarCargador()
            })
        })

    }


    return (
        <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="flex-start"
        >

            <Cargador/>

            <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="flex-start"
                sx={{maxWidth: '1400px', px: 3}}
            >

                <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'center'}}>
                    <Typography sx={{fontSize: 34, fontWeight: 700}}>Se nuestro socio</Typography>
                </Grid>

                <Grid item container lg={8} sm={8} xs={12} sx={{justifyContent: 'center', marginTop: 2}}>
                    <Typography sx={{fontSize: 18, fontWeight: 400, textAlign: 'center'}}>Se parte de nuestro equipo de
                        ventas a nivel
                        nacional y obtiene mayor margen de beneficios y todo lo que ademas tenemos preparado para
                        ti</Typography>
                </Grid>

                <Grid item container lg={6} sm={8} xs={12} sx={{justifyContent: 'center', marginTop: 10}}>
                    <Formulario_socio props={props}/>
                </Grid>


                <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'center', marginTop: 4}}>
                    <LoadingButton
                        onClick={() => guardar()}
                        variant={'contained'}>Enviar Información</LoadingButton>
                </Grid>

            </Grid>

        </Grid>
    )

}
export default NuestroSocio