/**************************************************
 * Nombre:       Home
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     1 Hr
 **************************************************/
import {Button, Grid, useMediaQuery} from "@mui/material";
import Propusta_Valor from "./ComponentesSecciones/Propusta_Valor";
import Tarjeta_Anuncio from "./ComponentesSecciones/Tarjeta_Anuncio";
import BarraCategorias from "./ComponentesSecciones/BarraCategorias";
import Titulo_Texto from "./ComponentesSecciones/Titulo_Texto";
import Catalogo from "./ComponentesSecciones/Catalogo";
import FraseHumanizadora from "./ComponentesSecciones/FraseHumanizadora";
import LogosFraces from "./ComponentesSecciones/LogosFraces";
import {theme} from "../../Tema";
import {useEffect, useState} from "react";
import {obtenerColeccionQuery} from "../../Servicios/BD/obtenerColeccionQuery";
import {collection, limit, orderBy, query} from "firebase/firestore";
import {fire} from "../../fire";
import {useNavigate} from 'react-router-dom'
import {irArriba} from "../../Utilidades/irArriba";
import BotonPagos from "../../Modulo_Pasarela_Pagos/Componentes/BotonPagos";
import {useReciboPago} from "../../Modulo_Pasarela_Pagos/Hooks/useReciboPago";
import logo from '../../Recursos/logo_oscuro.png'
import {PDFViewer} from "@react-pdf/renderer";
import Pdf_Recibo from "../../Modulo_Pasarela_Pagos/Pdfs/Pdf_Recibo";

const Home = () => {
    const masSM = useMediaQuery(theme.breakpoints.up('xs'))
    const sCell = useMediaQuery(theme.breakpoints.only('xs'))
    const [productoDescuento, setPruducosDescuento] = useState([])
    const navigate = useNavigate()
    const {Dialogo} = useReciboPago({logo: logo})

    useEffect(() => {

        irArriba()
        let col = collection(fire, 'productos')
        let q = query(col, orderBy('descuento', 'desc'), limit(10))

        obtenerColeccionQuery(q).then((dox) => {
            if (dox.res) {
                setPruducosDescuento(dox.data)
            }
        })
    }, [])
    return (
        <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="flex-start"

        >

            <Dialogo/>

            <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="flex-start"
                sx={{maxWidth: '1400px', px: sCell ? 0 : 3}}
            >


                <Grid item container lg={6} sm={6} xs={12} sx={{justifyContent: 'flex-start', marginTop: 11}}>
                    <Propusta_Valor/>
                </Grid>

                <Grid item container lg={6} sm={6} xs={12} sx={{justifyContent: 'flex-start', marginTop: 11}}>
                    <Tarjeta_Anuncio/>
                </Grid>


            </Grid>

            <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'flex-start', marginTop: 12}}>
                <BarraCategorias/>
            </Grid>

            <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'center', marginTop: 10}}>
                <Titulo_Texto titulo={'Productos con descuento'}
                              descripcion={'En esta seccion encontraras un listado de productos con descuento, aprovechalos que son por tiempo limitado'}/>
            </Grid>

            <Grid item container lg={10} sm={10} xs={12} sx={{justifyContent: 'flex-start', marginTop: 12}}>
                <Catalogo data={productoDescuento}/>
            </Grid>

            <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'center', marginTop: 8}}>
                <Button
                    onClick={() => navigate('/Catalogo')}
                >Ver todo el catalogo</Button>
            </Grid>

            <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'flex-start', marginTop: 12}}>
                <LogosFraces/>
            </Grid>

            <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'flex-start', marginTop: 8}}>
                <FraseHumanizadora/>
            </Grid>

        </Grid>
    )

}
export default Home    