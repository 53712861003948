/**************************************************
 * Nombre:       Formulario_Productos
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     1 Hr
 **************************************************/
import {Button, Grid, Typography} from "@mui/material";
import IngresoImagen from "../../Modulo_formularios/Componentes/IngresoImagen";
import IngresoTexto from "../../Modulo_formularios/Componentes/IngresoTexto";
import {
    ArrowRight,
    ArrowRightAlt,
    AttachMoney, FaceRetouchingNatural, Flag,
    Height,
    LocalMall,
    LocalMallOutlined,
    LocalOffer, Scale, Spa, Star, Start,
    Upgrade
} from "@mui/icons-material";
import IngresoDinero from "../../Modulo_formularios/Componentes/IngresoDinero";
import IngresoCheckGrupo from "../../Modulo_formularios/Componentes/IngresoCheckGrupo";
import IngresoSelect from "../../Modulo_formularios/Componentes/IngresoSelect";
import {CATEGORIAS} from "../../Constantes";
import {useEffect, useState} from "react";
import {obtenerCol} from "../../Servicios/BD/obtenerCol";
import IngresoImagenCrop from "../../Modulo_formularios/Componentes/IngresoImagenCrop";
import {guardarDoc} from "../../Servicios/BD/guardarDoc";
import {useLoaders} from "../../Modulo_Loaders/Hooks/useLoaders";
import logo from '../../Recursos/logo_redondo.svg'

const Formulario_Productos = ({...props}) => {
    const [categorias, setCategorias] = useState([])
    const {Cargador, abrirCargador, cerrarCargador} = useLoaders({logo: logo})


    useEffect(() => {
        obtenerCol('categorias').then((dox) => {
            if (dox.res) {
                let catsObjeto = dox.data
                let arr = catsObjeto.map((it) => it.nombre)
                setCategorias(arr)
            }
        })
    }, [])
    return (
        <form noValidate style={{width: '100%'}}>
            <Cargador/>
            <Grid
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="center"
                spacing={2}
                sx={{p: 1}}
            >

                <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'center'}}>
                    <IngresoImagenCrop dato={'img'} {...props}/>
                </Grid>

                <Grid item container lg={6} sm={6} xs={12} sx={{justifyContent: 'flex-start'}}>
                    <IngresoSelect Icono={Flag} nombre={'Categoria'} dato={'categoria'}
                                   opciones={categorias} {...props}
                                   requerido={'La categoria del producto es requerido*'}
                    />
                </Grid>

                <Grid item container lg={6} sm={6} xs={6} sx={{justifyContent: 'flex-end'}}>
                    {props && props.id &&
                        <Button
                            variant={'text'}
                            sx={{color: '#00000080'}}
                            onClick={() => props.click()}
                            startIcon={<Star/>}>Anuncio</Button>
                    }
                </Grid>

                <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'center'}}>
                    <IngresoTexto Icono={LocalMall} nombre={'Nombre'} dato={'nombre'}  {...props}
                                  requerido={'El nombre del producto es requerido*'}
                    />
                </Grid>

                <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'center'}}>
                    <IngresoTexto nombre={'Descripcion'} dato={'descripcion'}  {...props}
                                  requerido={'La descripcion del producto es requerido*'} lineas={4}
                    />
                </Grid>

                <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'center'}}>
                    <IngresoTexto Icono={FaceRetouchingNatural} nombre={'Beneficio #1'}
                                  dato={'beneficio1'}  {...props}/>
                </Grid>

                <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'center'}}>
                    <IngresoTexto Icono={FaceRetouchingNatural} nombre={'Beneficio #2'}
                                  dato={'beneficio2'}  {...props}/>
                </Grid>

                <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'center'}}>
                    <IngresoTexto Icono={FaceRetouchingNatural} nombre={'Beneficio #3'}
                                  dato={'beneficio3'}  {...props}/>
                </Grid>

                <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'center'}}>
                    <IngresoTexto Icono={FaceRetouchingNatural} nombre={'Beneficio #4'}
                                  dato={'beneficio4'}  {...props}/>
                </Grid>

                <Grid item container lg={6} sm={6} xs={6} sx={{justifyContent: 'center'}}>
                    <IngresoDinero Icono={AttachMoney} nombre={'Precio Normal'} dato={'valor'}  {...props}
                                   requerido={'El precio normal del producto es requerido*'}
                    />
                </Grid>

                <Grid item container lg={6} sm={6} xs={6} sx={{justifyContent: 'center',}}>
                    <IngresoDinero Icono={LocalOffer} nombre={'Precio Rebajado'} dato={'rebajado'}  {...props}
                    />
                </Grid>

                <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'flex-start'}}>
                    <Typography sx={{color: '#00000090'}}>Datos de Envio</Typography>
                </Grid>

                <Grid item container lg={6} sm={6} xs={6} sx={{justifyContent: 'center'}}>
                    <IngresoTexto Icono={Height} nombre={'Alto (cm)'} dato={'alto'} type={'number'}  {...props}
                                  requerido={'El alto del producto es requerido*'}
                    />
                </Grid>

                <Grid item container lg={6} sm={6} xs={6} sx={{justifyContent: 'center'}}>
                    <IngresoTexto Icono={ArrowRightAlt} nombre={'Ancho (cm)'} dato={'ancho'} type={'number'}  {...props}
                                  requerido={'El Ancho del producto es requerido*'}
                    />
                </Grid>

                <Grid item container lg={6} sm={6} xs={6} sx={{justifyContent: 'center'}}>
                    <IngresoTexto Icono={Upgrade} nombre={'Fondo (cm)'} dato={'fondo'} type={'number'}  {...props}
                                  requerido={'El Fondo del producto es requerido*'}
                    />
                </Grid>

                <Grid item container lg={6} sm={6} xs={6} sx={{justifyContent: 'center'}}>
                    <IngresoTexto Icono={Scale} nombre={'Peso (gr)'} dato={'peso'} type={'number'}  {...props}
                                  requerido={'El Peso del producto es requerido*'}
                    />
                </Grid>

            </Grid>
        </form>

    )

}
export default Formulario_Productos