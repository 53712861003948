/**************************************************
 * Nombre:       ValoresTotales
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     1 Hr
 **************************************************/
import {CircularProgress, Divider, Grid, Typography} from "@mui/material";
import epayco from '../Recursos/epayco.png'
import {useEffect, useState} from "react";
import {useCantidadesProducto} from "../Hooks/useCantidadesProducto";
import {formatoMoneda} from "../../Utilidades/formatoMoneda";
import BotonPagos from "../../Modulo_Pasarela_Pagos/Componentes/BotonPagos";
import {adaptador_pedido} from "../Adaptadores/adaptador_pedido";
import {guardarDoc} from "../../Servicios/BD/guardarDoc";
import {obtenerDoc} from "../../Servicios/BD/obtenerDoc";
import {
    enviarNotificacionMultiplesTokens
} from "../../Modulo_Notificaciones/Funciones/enviarNotificacionMultiplesTokens";

const ValoresTotales = ({obtenerEntidad, idPedido, setIdPedido}) => {
    const {valorTotal, cantidadesProducto} = useCantidadesProducto()
    const [valorEnvio, setValorEnvio] = useState(0)
    const [total, setTotal] = useState(0)
    const [guardando, setGuardando] = useState(false)

    const enviarNotificaciones = (entidad) => {
        obtenerDoc('tokens', 'tokens').then((dox) => {
            if (dox.res) {
                let arrTokens = dox.data
                console.log(dox)
                if (arrTokens) {
                    enviarNotificacionMultiplesTokens({
                        arrTokens: arrTokens.arrTokens,
                        titulo: 'Nuevo Pedido',
                        mensaje: `${entidad.nombre} ${entidad.apellido} paso a caja`
                    })
                }
            }
        })
    }

    useEffect(() => {
        setGuardando(true)
        obtenerEntidad().then((entidad) => {
            let valEnvio = entidad.valorEnvio
            setValorEnvio(valEnvio)
            let total = valorTotal + valEnvio
            setTotal(total)
            let pedido = adaptador_pedido({productos: cantidadesProducto, valorEnvio: valEnvio, datos: entidad})
            if (idPedido !== '') {
                pedido.id = idPedido
            }
            pedido.estadoPago = 'Abandonado'
            pedido.estado = 'nuevo'
            guardarDoc('pedidos', pedido).then((dox) => {
                if (dox.res) {
                    setGuardando(false)
                    setIdPedido(dox.data)
                    enviarNotificaciones(entidad)


                } else {
                    setGuardando(false)
                }

            })


        })
    }, []);
    return (
        <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="flex-start"
            sx={{px: 0, pt: 8, pb: 0}}
        >

            <Grid item container lg={9} sm={9} xs={9} sx={{justifyContent: 'flex-start'}}>
                <Grid
                    container
                    direction="row"
                    justifyContent="center"
                    alignItems="flex-start"
                >

                    <Grid item container lg={6} sm={6} xs={6} sx={{justifyContent: 'flex-start'}}>
                        <Typography sx={{color: '#00000090', fontSize: 14}}>Valor Productos</Typography>
                    </Grid>

                    <Grid item container lg={6} sm={6} xs={6} sx={{justifyContent: 'flex-end'}}>
                        <Typography sx={{
                            color: '#000000',
                            fontWeight: 600,
                            fontSize: 14
                        }}>{formatoMoneda(valorTotal)}</Typography>
                    </Grid>

                    <Grid item container lg={6} sm={6} xs={6} sx={{justifyContent: 'flex-start'}}>
                        <Typography sx={{color: '#00000090', fontSize: 14}}>Valor Envio</Typography>
                    </Grid>

                    <Grid item container lg={6} sm={6} xs={6} sx={{justifyContent: 'flex-end'}}>
                        <Typography sx={{
                            color: '#000000',
                            fontWeight: 600,
                            fontSize: 14
                        }}>{formatoMoneda(valorEnvio)}</Typography>
                    </Grid>

                    <Grid item container lg={12} sm={12} xs={12}
                          sx={{justifyContent: 'flex-start', marginTop: 1, marginBottom: 1}}>
                        <Divider sx={{width: '100%'}}/>
                    </Grid>

                    <Grid item container lg={6} sm={6} xs={6} sx={{justifyContent: 'flex-start'}}>
                        <Typography sx={{color: '#000000', fontSize: 14, fontWeight: 600}}>Valor Total</Typography>
                    </Grid>

                    <Grid item container lg={6} sm={6} xs={6} sx={{justifyContent: 'flex-end'}}>
                        <Typography
                            sx={{color: '#000000', fontWeight: 600, fontSize: 14}}>{formatoMoneda(total)}</Typography>
                    </Grid>


                </Grid>
            </Grid>


            <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'center', marginTop: 4}}>
                {guardando ?

                    <Grid
                        container
                        direction="row"
                        justifyContent="center"
                        alignItems="flex-start"
                    >

                        <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'center'}}>
                            <Typography>Generando Pedido</Typography>
                        </Grid>

                        <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'center', marginTop: 1}}>
                            <CircularProgress/>
                        </Grid>

                    </Grid>
                    :
                    <BotonPagos valor={total} idPedido={idPedido}/>
                }

            </Grid>

            <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'center', marginTop: 4}}>
                <img src={epayco} width={'100%'} height={'auto'}/>
            </Grid>


        </Grid>
    )

}
export default ValoresTotales