/**************************************************
 * Nombre:       Catalogo
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     1 Hr
 **************************************************/
import {Grid, useMediaQuery} from "@mui/material";
import Tarjeta_Producto from "../Tarjetas/Tarjeta_Producto";
import {theme} from "../../../Tema";

const Catalogo = ({data = []}) => {
    const masSM = useMediaQuery(theme.breakpoints.up('xs'))
    const sCell = useMediaQuery(theme.breakpoints.only('xs'))


    return (
        <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="flex-start"
        >
            <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="flex-start"
                sx={{maxWidth: '1400px', px: 3}}
                spacing={sCell ? 2 : 8}
            >

                {data.map((item, index) => {
                    return (
                        <Grid key={`dsw-${index}`} item container lg={6} sm={6} xs={6}
                              sx={{justifyContent: 'flex-start'}}>
                            <Tarjeta_Producto item={item}/>
                        </Grid>
                    )
                })}

            </Grid>


        </Grid>
    )

}
export default Catalogo